.pollVote {
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 0 1rem;
}

.vote__title {
  font-size: 1.25rem;
  color: rgba(117, 111, 134, 1);
  font-weight: bolder;
}

.pollVote .form {
  width: 100%;
}

.form__question {
  font-size: 1rem;
  color: #333;
  font-weight: bolder;
  word-wrap: break-word;
  margin-top: 0px;
}

.form__list {
  list-style-type: none;
  margin: 0.5rem 0;
  padding: 0;
}

.form__item {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  margin: 1rem 0rem;
  border-radius: 0.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  word-wrap: break-word;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: white;
}

.form__iconButton {
  border: 2px solid rgb(201, 201, 201) !important;
  height: 1.5rem;
  width: 1.5rem;
  margin: 1rem !important;
}

/* @media (max-width: 600px) {
  .form__iconButton {
    padding: 1rem !important;
  }
} */

.form__Opt {
  word-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 600px) {
  .form__Opt {
    font-size: 0.9rem;
    padding: 0 0 0 0.5rem;
  }
}

.form__btnGroup {
  text-align: center;
  padding: 0.4rem 0rem;
}

.form__btnGroup > button {
  /* font-size: 1.25rem; */
  width: 100%;
}

.form__checkIcon {
  color: rgba(244, 246, 250, 1);
}

.form__iconButton--active {
  border-color: rebeccapurple !important;
}
.form__checkIcon--active,
.form__item--active {
  color: rebeccapurple !important;
}
.pollVote .form__btn {
  background-color: rgba(39, 234, 150, 1) !important;
  color: white;
  border-radius: 25px;
  text-transform: capitalize;
}
.lockStatus {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.lockStatus__icon {
  color: #bdbdbd;
}
.lockStatus__msg {
  text-transform: capitalize;
  color: #bdbdbd;
}
.pollVote .form_btn--disabled {
  background-color: #bdbdbd !important;
}
.pollVote__no-poll {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.poll-image-body {
  height: 5.625rem;
  max-width: 5.375rem;
  align-self: flex-start;
}

.poll-image-body > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

@media (min-width: 768px) {
  .form__btnGroup > button {
    width: 7.625rem;
  }
}
