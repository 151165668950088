.drawer {
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
  transition: 0.5s linear;
}

.drawer.hideDrawer {
  background-color: transparent;
  left: -100rem;
  transition: 0.5s linear;
}

.drawer__container {
  height: 100%;
  width: 80%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: white;
}

.drawer__header {
  border-bottom: 2px solid rgb(203 147 245);
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  background-color: rgba(150, 39, 234, 1);
  border-top-right-radius: 1rem;
}

.drawer__header .leftArrowIcon {
  background-color: white;
  border-radius: 50%;
}

.drawer__header p {
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.drawer__header .settingBtn {
  color: white;
  margin-left: auto;
}

.drawer__meeting {
  border-bottom-right-radius: 1rem;
  background-color: rgba(150, 39, 234, 1);
  padding: 0.8rem 1rem;
}

.drawer__meeting p {
  margin: 0 0 0.5rem 0rem;
  font-weight: bold;
  color: white;
  letter-spacing: 0.1rem;
}

.drawer__meeting span {
  color: white;
  font-size: 0.9rem;
}

.drawer__list {
  list-style: none;
  padding: 0rem;
  border-bottom: 2px solid rgba(117, 111, 134, 0.502);
  height: 56vh;
  overflow: auto;
  scrollbar-width: none;
}

.drawer__list::-webkit-scrollbar {
  display: none;
}

.drawer__list li {
  padding: 1rem 0.7rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.drawer__list li.menuActive {
  color: rgba(150, 39, 234, 1);
  border-left: 0.3rem solid rgba(150, 39, 234, 1);
  background: rgba(244, 246, 250, 1);
}

.drawer__list li .drawer__linkName {
  margin-left: 0.5rem;
}

.drawer__about {
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 700;
}
.drawer__about a {
  color: rgba(117, 111, 134, 1);
}

.drawer__actions {
  display: flex;
  align-items: center;
}

.drawer__actions .ml-auto {
  margin-left: auto;
}

.modal__fade--sm {
  width: 90% !important;
}

@media (max-width: 350px) {
  .drawer__list {
    height: 49vh;
  }
}

@media (min-width: 770px) {
  .drawer {
    display: none;
  }
}
