.rSidebarItem {
  margin: 0.675rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SidebarPanel {
  position: relative;
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: #f4f6fa;
  height: calc(100vh - 6.5rem);
}

.RSTextField {
  position: absolute !important;
  bottom: 0.75rem;
  left: 0.5rem;
  right: 0.5rem;
  background-color: white;
}
.Badge {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: green;
  border: 2px solid white;
}

.DiscussionMsg {
  display: flex;
  /* align-items: center; */
  gap: 0.5rem;
  margin: 0.75rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: white;
}
.DiscussionMsgText {
  flex-grow: 1;
  /* padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background-color: white; */
}

.QnaList {
  display: flex;
  gap: 0.375rem;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid #888;
}
.QnaListHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.125rem;
  margin-bottom: 0.125rem;
}

.UserList {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: white;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}
.UserList:hover {
  background-color: #fcfdfd;
}
.SelfChatRow {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.ChatRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.ChatBubble {
  position: relative;
  padding: 0.5rem 0.75rem;
  margin-left: 1.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.ChatBubble::before {
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-left: 20px solid transparent;
  left: -20px;
  top: 0;
}
.SelfChatBubble {
  position: relative;
  padding: 0.5rem 0.75rem;
  margin-right: 1.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.SelfChatBubble::before {
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right: 20px solid transparent;
  right: -20px;
  top: 0;
}

.Chats {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-between;
}
.ChatHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.SidebarListItem {
  display: flex;
  gap: 0.5rem;
  padding: 0.375rem 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  align-items: center;
  margin: 1rem 0;
}
.ListItemTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #444;
}
.DateSeperator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.DateSeperator small {
  color: rgba(123, 102, 102, 0.4);
  white-space: nowrap;
}
.DateSeperator span {
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.time {
  color: rgba(123, 102, 102, 0.4);
  white-space: nowrap;
}
