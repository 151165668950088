.avatar__btn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.rightSideBar__menuItem {
  margin: 4px !important;
  border-radius: 1rem !important;
}

.rightSideBar__menuItem--active {
  border: 2px solid rgba(150, 39, 234, 1) !important;
  color: rgba(150, 39, 234, 1) !important;
}

.rightSideBar__menu .MuiMenu-list {
  min-width: 11rem;
}

.menu__icon--active {
  color: rgba(150, 39, 234, 1) !important;
}

/* ///// profile modal///// */
.profileModal .profileModal__avatarContainer {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.profileModal .profileModal__avatarContainer img {
  height: 100%;
  width: 100%;
}

.profileModal__avatarContainer:hover .profileModal__addImg {
  visibility: visible;
}

.profileModal .profileModal__avatarIcon {
  height: 100%;
  width: 100%;
  color: rgba(82, 78, 78, 1);
}

.profileModal .profileModal__addImg {
  position: absolute;
  height: 3rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.profileModal .profileModal__cameraIcn {
  color: rgb(195, 195, 195);
}

.profileModal .profileModal__form {
  margin: 1rem 0;
}

.profileModal .profileModal__form .formGroup__wrapper {
  display: flex;
}

.profileModal .profileModal__form .formGroup {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.profileModal .formGroup > label {
  text-transform: capitalize;
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  font-weight: bold;
}
.profileModal .formGroup div {
  height: 3rem;
}

.profileModal .formGroup div > input {
  height: 100%;
  outline: none;
  border: 0;
  background-color: rgba(233, 239, 246, 1);
  border-radius: 0.6rem;
  font-size: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.profileModal .formGroup div > input:focus {
  border: 2px solid var(--purple);
}

.profileModal .btn_group {
  padding: 0 1rem;
  text-align: right;
}

.cash-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.live-event-menu {
  display: none;
  background-color: #808080;
  height: 2.625rem;
  width: 2.625rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 0;
  cursor: pointer;
  transition: all 300ms linear;
}

.live-event-menu.menu-active {
  background-color: #9627ea;
}

@media (max-width: 600px) {
  .profileModal .profileModal__avatarContainer {
    height: 5rem;
    width: 5rem;
  }

  .profileModal .profileModal__form .formGroup__wrapper {
    display: flex;
    flex-direction: column;
  }

  .profileModal .profileModal__form .formGroup {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .live-event-menu {
    display: flex;
  }
}
