/* event dashboard styles */
.event-dashboard {
  position: sticky;
  top: 0;
  display: flex;
  min-height: 80px;
  border-bottom: 1px solid lightgray;
  z-index: 999;
  background-color: white;
}
.event-dashboard h3,
.event-dashboard p {
  margin: 0;
}

.dashboard {
  display: flex;
  min-height: 80px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  z-index: 999;
}

.preview-btn-container {
  display: inline-flex;
  border: 1px solid lightgray;
  padding: 4px 6px;
  border-radius: 6px;
  align-items: center;
}

.preview-btn-container .preview-txt {
  font-size: 0.875rem;
  margin-right: 0.5rem;
  color: black !important;
}
.preview-icon {
  border: 1px solid lightgray !important;
  box-shadow: 0 0 3px gray;
  height: 28px;
  width: 28px;
}
.preview-btn-container button {
  margin: 0 3px;
}

.eventDashboard_icn {
  color: black !important;
}

.dashboard-left {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.dashboard-left .back-btn {
  border: 1px solid black;
}
.present-btn {
  background-color: var(--btn-secondary);
}
.test {
  padding: 0 8px;
}
.event-dashboard-name {
  display: flex;
}
.app-name {
  font-size: 1.25rem;
}
.gray-p {
  color: rgb(92, 88, 88);
  font-size: 0.75rem;
}

.dashboard-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.dashboard-right .MuiButton-startIcon {
  margin-right: 4px !important;
}
.dashboard-right .MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}
.dashboard-right span {
  color: white;
}
.dashboard-right .MuiButton-root {
  margin: 0 6px;
  border-radius: 6px !important;
}

.eventContent__container {
  position: relative;
}

.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

.smartphone {
  position: relative;
  width: 412px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

.smartphone__content {
  height: 100%;
  width: 100%;
}

.laptop {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
  transform: scale(0.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
  left: 50%;
  position: absolute;
  width: 1366px;
  height: 800px;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 24px 24px 80px;
  background-color: black;
  top: 10%;
}

.laptop:after {
  content: '';
  display: block;
  position: absolute;
  width: 1600px;
  height: 60px;
  margin: -30px 0 0 -130px;
  background: black;
  border-radius: 6px;
}

.laptop .content {
  width: 1320px;
  height: 800px;
  overflow: hidden;
  border: none;
}
@media (max-width: 960px) {
  .preview-btn-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard,
  .event-dashboard {
    display: none;
  }
  .event-dashboard-menu {
    position: relative;
    margin-top: 8rem;
  }
}

@media (max-width: 600px) {
  .event-dashboard-menu-button {
    margin: 5px 0 0 16px !important;
  }
}

@media (max-width: 360px) {
  .event-dashboard-menu {
    margin-top: 6.5rem !important;
  }
}
