.ratingVote {
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 0 1rem;
}

.ratingVote p {
  padding: 0.45rem 0;
}
.ratingVote p small {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: gray;
}
.ratingVote-card {
  min-width: 304px;
  max-width: 600px;
  border: 1px solid lightgray;
  border-radius: 0.75rem;
  background-color: white;
  box-shadow: 0 0 10px lightgray;
}

.ratingVote .card-header {
  padding: 1rem;
  word-wrap: break-word;
  margin: 0;
}
.rating-container {
  text-align: center;
  padding: 0.5rem 0;
}
.rating-container h4 {
  padding: 0.5rem 0;
}
.ratingVote-btn-container {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.ratingVote-btn-container button {
  color: white;
  padding: 5px 1.5rem;
  border-radius: 2rem !important;
}
.ratingVote-edit-res-btn {
  border-radius: 2rem !important;
  margin: 4px 0 !important;
  padding: 4px 1rem !important;
  background-color: white !important;
}

.ratingVote-graph-container {
  text-align: center;
  padding: 1rem;
}
.ratingVote-graph {
  min-height: 250px;
}

@media (max-width: 600px) {
  .ratingVote-card {
    width: 100%;
    min-width: 100%;
  }
  .ratingVote-graph {
    height: 220px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .ratingVote-card {
    width: 100%;
    max-width: 100%;
  }
}
