.myLoader {
  position: relative;
  background: no-repeat center rgb(245 239 254);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ///// media query ///// */

@media (max-width: 768px) {
  .myLoader {
    width: 100vw;
  }
  .myLoader > img {
    width: 100%;
  }
}
