.quizVote {
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 0.5rem 1rem 0 1rem;
}

.quizVote span {
  margin: 0;
  word-break: break-word;
}

.quizBox {
  word-wrap: break-word;
}
.quizBox__title {
  margin: 0;
  font-size: 1rem;
  color: #7b6666;
}

.quizBox__list {
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.quizBox__listItem {
  border: 0.797452px solid rgba(128, 128, 128, 0.2);
  box-shadow: 0.797452px 0.797452px 3.18981px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-top: 0.5rem;
}

.quizBox__listItem--question {
  font-weight: bold;
  border: none;
  border-radius: 0;
  box-shadow: none;
  pointer-events: none;
}

.quizBox__listItem--question .quizQuestion {
  font-weight: bold;
  font-size: 1.25rem;
  color: #000;
}

.quizBox__image {
  height: 5.375rem;
  width: 5.625rem;
  align-self: flex-start;
}

.quizBox__image > img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  object-fit: cover;
}

.quizBox__vector {
  margin: auto;
}

.quizBox__vector > img {
  height: 100%;
  width: 100%;
}

.quizBox__option {
  font-size: 1.063rem;
  color: rgba(117, 111, 134, 1);
  font-weight: 600;
  padding: 0.25rem;
}
.quizBox__iconButton {
  border: 1px solid rgba(117, 111, 134, 1) !important;
  margin: 1rem !important;
}
.quizBox__iconButton--active {
  background-color: rebeccapurple !important;
}
.quizBox__iconButton--active:hover {
  background-color: rebeccapurple !important;
}
.quizBox__icon--active {
  color: #fff;
}
.quizBox__nav {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.quizBox__prevBtn,
.quizBox__nextBtn {
  background-color: rebeccapurple !important;
  height: 1.5rem;
  width: 1.5rem;
  margin-inline: 10px !important;
}
.quizBox__prevIcon,
.quizBox__nextIcon {
  color: white !important;
}

.quizBox__prevBtn:hover,
.quizBox__nextBtn:hover {
  background-color: rebeccapurple;
}
.quizBox__counter {
  color: rgba(117, 111, 134, 1);
  font-size: bold;
  font-weight: 500;
}
.quizBox__btnGroup {
  text-align: center;
  margin-top: 1.25rem;
}

.submitBtn {
  width: 100%;
}

.submitBtn--disabled {
  background: rgb(136, 136, 136) !important;
  color: white !important;
}

.quizVote .quizBox__lockStatus {
  color: #baba;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 500;
}

.quiz-timer {
  padding-block: 1.25rem;
  max-width: 50rem;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quiz-timer .timer-progress-wrapper {
  height: 0.35rem;
  background-color: rgb(225, 225, 225);
  border-radius: 3rem;
  flex-basis: 80%;
}

.quiz-timer .timer-progress-bar {
  height: 100%;
  border-radius: 3rem;
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  transition-property: 'width';
}

.quiz-timer .timer-count {
  flex-basis: 20%;
  text-align: right;
  letter-spacing: 0.05rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: #7b6666 !important;
  min-width: 4.75rem;
  text-align: center;
}

.anonymous-voting-text {
  font-weight: 500;
  font-size: 1rem;
  color: #808080;
  line-height: 1.25rem;
  margin: 1rem 0 0.5rem 0;
}

.anonymous-voting-text span {
  font-weight: 600;
  color: #363636;
}

@media (max-width: 600px) {
  .quizVote * {
    font-size: 1rem !important;
    font-weight: 500;
  }
}

@media (min-width: 768px) {
  .quizBox__listItem--question .quizQuestion {
    font-size: 1.25rem;
  }
  .submitBtn {
    width: 7.625rem;
  }
  .quizBox__title {
    font-size: 1.2rem;
  }
}
