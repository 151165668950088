.shimmerCard__container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.shimmerCard__body {
  height: 18rem;
  width: 100%;
  max-width: 40rem;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
}

.shimmerCard__center {
  display: flex;
  justify-content: center;
}
