.resetPassword.loginMsg {
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
}

.resetPassword.loginRoute {
  text-decoration: underline;
}
