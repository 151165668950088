/* .content {
  box-sizing: border-box;
}
@media (max-width: 800px) {
  .content {
    width: 100vw;
  }
}
@media (max-width: 600px) {
  .content {
    width: 100vw;
  }
} */

#toggleBtn {
  position: fixed;
  top: 50%;
  left: 30px;
  z-index: 5000;
}
