.newLogin {
  position: relative;
  background-color: rgba(245, 245, 245, 1);
  min-height: 100vh;
}
.newLogin *,
.newLogin *::after,
.newLogin *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-bottom: none;
}

.newLogin .header {
  padding: 1rem 2rem;
}

.newLogin .header__list {
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 100%;
}
.newLogin .header__list > .brand {
  font-size: 1.5rem;
  color: var(--primary);
  font-weight: bold;
}

.newLogin .header__list > .btnGroup {
  margin-left: auto;
}

.newLogin .btnGroup > .btnGroup__btn {
  /* font-size: 1rem; */
  border-radius: 2rem;
}

.newLogin .login__btn {
  border-radius: 2rem;
}
.newLogin .landing__imgWrapper {
  max-width: 16rem;
  max-height: 16rem;
  /* margin-inline: auto; */
  margin-left: auto;
  margin-right: auto;
}

.newLogin .landing__imgWrapper > img {
  height: 100%;
  width: 100%;
}

.newLogin .formGroup {
  max-width: 28rem;
  /* margin-inline: auto; */
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}

.newLogin .inputGroup {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.newLogin .inputGroup > label {
  padding-block: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(117, 111, 134, 1);
}

.newLogin .formGroup__form .btnGroup {
  text-align: center;
  margin: 1rem 0;
  width: 100%;
}

.newLogin .inputGroup__input {
  background-color: #8ecfe21f !important;
  border-radius: 0.4rem;
}
.newLogin .MuiFilledInput-input {
  padding: 18px 12px 16px !important;
}

.newLogin .MuiFilledInput-root,
.newLogin .MuiFilledInput-root:hover {
  background-color: unset !important;
}

.MuiFilledInput-adornedEnd {
  padding-right: 0.3rem !important;
}

.newLogin .inputGroup__input .MuiFilledInput-underline:before {
  border-bottom: unset !important;
}
.newLogin .inputGroup__input .MuiFilledInput-underline:hover:before {
  border-bottom: unset !important;
}

.newLogin__opt {
  font-size: 1rem;
  font-weight: bold;
}
.newLogin__opt span {
  color: var(--primary);
  cursor: pointer;
}
.newLogin__opt2 {
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}
.newLogin__opt2 span {
  color: var(--primary);
  cursor: pointer;
}

.newLogin .loginOpt {
  text-align: center;
  margin-top: 0.4rem;
  /* font-size: 1rem; */
  font-weight: bold;
}

.newLogin .loginOpt > span,
.newLogin .loginOpt > a {
  color: var(--primary);
  cursor: pointer;
}

.newLogin .link__container {
  width: 100%;
  margin-top: 0.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.newLogin .googleMsg {
  text-align: center;
  margin-block: 1.5rem;
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  position: relative;
}

.newLogin .googleMsg::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 8rem;
  background-color: rgba(112, 112, 112, 0.502);
  top: 50%;
  left: 0;
}

.newLogin .googleMsg::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 8rem;
  background-color: rgba(112, 112, 112, 0.502);
  top: 50%;
  right: 0;
}

.newLogin .googleicn {
  height: 3rem;
  width: 4.2rem;
  border: 1px solid rgba(112, 112, 112, 0.502);
  margin: auto;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-block: 0.5rem;
}

.newLogin .googleicn > img {
  height: 100%;
  width: 100%;
}

.newLogin .signupDiv {
  height: 50%;
  width: 28%;
  position: absolute;
  left: 0;
  bottom: 0;
  /* border: 2px solid red; */
  overflow: hidden;
}

.newLogin .signupDiv > .signupDiv__circle {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.newLogin #main__circle {
  height: 111%;
  width: 111%;
  border-radius: 50%;
  position: absolute;
  left: -1rem;
  box-shadow: inset 0 0 55px 7px white;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2rem;
}

.newLogin .signupDiv__msg {
  font-size: 1.25rem;
  line-height: 1.2rem;
  text-shadow: 1px 1px 5rem var(--primary);
}

.newLogin .signupDiv__msg > .loginOpt {
  text-align: left;
  margin-top: 2rem;
}

.newLogin .signupDiv--sm,
.newLogin .joinEvent--sm {
  display: none;
}

.newLogin .forgotPass {
  color: rgba(117, 111, 134, 1);
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
}

/* ////////media queries////////// */
@media (min-width: 1280px) {
  .newLogin #main__circle {
    left: -3rem;
  }
}
@media (max-width: 960px) {
  .signupDiv {
    display: none;
  }
  .newLogin .loginOpt {
    font-size: 1.2rem;
  }
  .newLogin .signupDiv--sm {
    display: block;
    margin-bottom: 1rem;
  }
}
@media (max-width: 600px) {
  .newLogin .hide--sm {
    display: none;
  }
  .newLogin .header__list {
    justify-content: center;
  }
  .newLogin .header__list > .brand {
    font-size: 2rem;
  }
  .newLogin .landing__imgWrapper {
    max-width: 14rem;
    max-height: 14rem;
  }

  .formGroup {
    padding-inline: 1rem;
  }

  .signupDiv {
    display: none;
  }

  .newLogin .googleMsg::after,
  .newLogin .googleMsg::before {
    width: 4rem;
  }

  .newLogin .loginOpt {
    font-size: 1rem;
  }
  .newLogin .signupDiv--sm {
    display: block;
  }
  .newLogin .btnGroup__btn {
    margin: 1rem auto;
    text-align: center;
    border-radius: 2rem;
  }

  .newLogin .joinEvent--sm {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newLogin {
    padding-inline: 5px;
  }
  .newLogin .inputGroup > label {
    font-size: 0.87rem;
  }
}
