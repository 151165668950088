@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
/* .preview-btn-container {
  display: inline-flex;
  border: 1px solid lightgray;
  padding: 6px;
  border-radius: 5px;
} */

/* event dashboard styles */
.event-dashboard {
  position: sticky;
  top: 0;
  display: flex;
  min-height: 80px;
  border-bottom: 1px solid lightgray;
  z-index: 999;
  background-color: white;
}
.event-dashboard h3,
.event-dashboard p {
  margin: 0;
}

.dashboard {
  display: flex;
  min-height: 80px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  z-index: 999;
}

.preview-btn-container {
  display: inline-flex;
  border: 1px solid lightgray;
  padding: 4px 6px;
  border-radius: 6px;
  align-items: center;
}

.preview-btn-container .preview-txt {
  font-size: 0.875rem;
  margin-right: 0.5rem;
  color: black !important;
}
.preview-icon {
  border: 1px solid lightgray !important;
  box-shadow: 0 0 3px gray;
  height: 28px;
  width: 28px;
}
.preview-btn-container button {
  margin: 0 3px;
}

.eventDashboard_icn {
  color: black !important;
}

.dashboard-left {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}
.dashboard-left .back-btn {
  border: 1px solid black;
}
.present-btn {
  background-color: var(--btn-secondary);
}
.test {
  padding: 0 8px;
}
.event-dashboard-name {
  display: flex;
}
.app-name {
  font-size: 1.25rem;
}
.gray-p {
  color: rgb(92, 88, 88);
  font-size: 0.75rem;
}

.dashboard-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.dashboard-right .MuiButton-startIcon {
  margin-right: 4px !important;
}
.dashboard-right .MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}
.dashboard-right span {
  color: white;
}
.dashboard-right .MuiButton-root {
  margin: 0 6px;
  border-radius: 6px !important;
}

.eventContent__container {
  position: relative;
}

.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

.smartphone {
  position: relative;
  width: 412px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

.smartphone__content {
  height: 100%;
  width: 100%;
}

.laptop {
  transform-origin: 0 0; /* Scaled down for a better Try-it experience (change to 1 for full scale) */
  transform: scale(0.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
  left: 50%;
  position: absolute;
  width: 1366px;
  height: 800px;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 24px 24px 80px;
  background-color: black;
  top: 10%;
}

.laptop:after {
  content: '';
  display: block;
  position: absolute;
  width: 1600px;
  height: 60px;
  margin: -30px 0 0 -130px;
  background: black;
  border-radius: 6px;
}

.laptop .content {
  width: 1320px;
  height: 800px;
  overflow: hidden;
  border: none;
}
@media (max-width: 960px) {
  .preview-btn-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard,
  .event-dashboard {
    display: none;
  }
  .event-dashboard-menu {
    position: relative;
    margin-top: 8rem;
  }
}

@media (max-width: 600px) {
  .event-dashboard-menu-button {
    margin: 5px 0 0 16px !important;
  }
}

@media (max-width: 360px) {
  .event-dashboard-menu {
    margin-top: 6.5rem !important;
  }
}

.avatar__btn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.rightSideBar__menuItem {
  margin: 4px !important;
  border-radius: 1rem !important;
}

.rightSideBar__menuItem--active {
  border: 2px solid rgba(150, 39, 234, 1) !important;
  color: rgba(150, 39, 234, 1) !important;
}

.rightSideBar__menu .MuiMenu-list {
  min-width: 11rem;
}

.menu__icon--active {
  color: rgba(150, 39, 234, 1) !important;
}

/* ///// profile modal///// */
.profileModal .profileModal__avatarContainer {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.profileModal .profileModal__avatarContainer img {
  height: 100%;
  width: 100%;
}

.profileModal__avatarContainer:hover .profileModal__addImg {
  visibility: visible;
}

.profileModal .profileModal__avatarIcon {
  height: 100%;
  width: 100%;
  color: rgba(82, 78, 78, 1);
}

.profileModal .profileModal__addImg {
  position: absolute;
  height: 3rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.profileModal .profileModal__cameraIcn {
  color: rgb(195, 195, 195);
}

.profileModal .profileModal__form {
  margin: 1rem 0;
}

.profileModal .profileModal__form .formGroup__wrapper {
  display: flex;
}

.profileModal .profileModal__form .formGroup {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.profileModal .formGroup > label {
  text-transform: capitalize;
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  font-weight: bold;
}
.profileModal .formGroup div {
  height: 3rem;
}

.profileModal .formGroup div > input {
  height: 100%;
  outline: none;
  border: 0;
  background-color: rgba(233, 239, 246, 1);
  border-radius: 0.6rem;
  font-size: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.profileModal .formGroup div > input:focus {
  border: 2px solid var(--purple);
}

.profileModal .btn_group {
  padding: 0 1rem;
  text-align: right;
}

.cash-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.live-event-menu {
  display: none;
  background-color: #808080;
  height: 2.625rem;
  width: 2.625rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 0;
  cursor: pointer;
  transition: all 300ms linear;
}

.live-event-menu.menu-active {
  background-color: #9627ea;
}

@media (max-width: 600px) {
  .profileModal .profileModal__avatarContainer {
    height: 5rem;
    width: 5rem;
  }

  .profileModal .profileModal__form .formGroup__wrapper {
    display: flex;
    flex-direction: column;
  }

  .profileModal .profileModal__form .formGroup {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .live-event-menu {
    display: flex;
  }
}

.myLoader {
  position: relative;
  background: no-repeat center rgb(245 239 254);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ///// media query ///// */

@media (max-width: 768px) {
  .myLoader {
    width: 100vw;
  }
  .myLoader > img {
    width: 100%;
  }
}

.joinpage {
  height: 100vh;
}

.joinpage .navbar {
  box-sizing: border-box;
  height: 8rem;
}

.joinpage .navbar .navbar__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding-inline: 1.5rem;
  margin: 0;
}

.joinpage .navbar__item {
  flex-basis: 50%;
}

.joinpage .navbar__item.navbar__brand {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.joinpage .navbar__item:nth-child(2) {
  display: flex;
  justify-content: flex-end;
}
.joinpage .navbar__item:nth-child(2) > .navbar__social {
  text-align: center;
}

.joinpage .navbar__item:nth-child(2) > .navbar__social > p {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}

.joinpage .navbar__iconBtn,
.joinpage .navbar__iconBtn:hover {
  background: #fff;
  margin-inline: 0.5rem;
}
.joinpage .body {
  height: calc(100vh - 8rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.joinpage .qrContainer {
  width: 40%;
  height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.joinpage .qrContainer__code {
  border: 2px solid #baba;
  height: 21.5rem;
  width: 23.75rem;
  border-radius: 2rem;
}

.joinpage .qrContainer__code > p {
  background: #ffffff;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 1.5rem;
  color: rgba(150, 39, 234, 1);
  font-weight: bold;
  height: 4rem;
}

.joinpage .qrContainer__code > div {
  height: calc(100% - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.joinpage .qrContainer__code > div > div {
  height: 80%;
  width: 60%;
}

.joinpage .my_qr {
  height: 100% !important;
  width: 100% !important;
}

.joinpage .qrContainer__desc {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  line-height: 0.4rem;
}
.joinpage .qrContainer__desc p{
  margin: 2rem 0;
}

@media (max-width: 1024px) {
  .joinpage .qrContainer {
    width: 100%;
  }
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
}

@media (max-width: 768px) {
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
}

@media (max-width: 600px) {
  .joinpage .qrContainer__code > p {
    font-size: 1rem;
  }
  .joinpage .qrContainer__code {
    width: 86%;
    height: 20.625rem;
  }
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
  .joinpage .navbar__item.navbar__brand {
    font-size: 1.2rem;
  }
  .joinpage .navbar__iconBtn {
    height: 1.4rem;
    width: 1.4rem;
  }
}

@media (max-width: 350px) {
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
  .joinpage .qrContainer__desc {
    font-size: 1.4rem;
  }
}

.newsignup {
  background-color: rgba(245, 245, 245, 1);
  padding: 1rem 1rem 0 1rem;
  min-height: 100vh;
}

.newsignup .newsignup__container {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.newsignup .newsignup__header {
  display: block;
}

.newsignup .newSignup__list {
  display: flex;
  list-style-type: none;
  align-items: center;
  /* padding: 0.5rem 2rem; */
  margin: 0;
}

.newsignup .newSignup__listItem {
  flex-basis: 50%;
  text-align: right;
}

.newsignup .brandName {
  font-size: 1.5rem;
  color: var(--primary);
  font-weight: bold;
}

.newsignup .newSignup__listItem > .newSignup__btn {
  border-radius: 2rem;
}

.newsignup .leftCol,
.newsignup .rightCol {
  flex-basis: 46%;
}

.newsignup .lefCol__msgContainer {
  display: flex;
  justify-content: flex-end;
}

.newsignup .leftCol__title > h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.newsignup .leftCol__title > p {
  font-weight: bold;
  font-size: 1.5rem;
}

.newsignup .leftCol__imgContainer {
  max-height: 23rem;
  padding-top: 2rem;
  overflow-y: clip;
}

.newsignup .leftCol__imgWrapper {
  position: relative;
  height: 26rem;
  width: 26rem;
  padding: 3rem;
  border-radius: 50%;
  margin-left: -5rem;
  box-shadow: inset 0 0 55px 20px white;
}

.newsignup .img__shadow {
  position: absolute;
  top: 35%;
  left: 35%;
  height: 5rem;
  width: 7rem;
  border-radius: 50%;
  box-shadow: 1px 1px 7rem 40px #9627ea;
}

.newsignup .leftCol__imgWrapper > img {
  /* height: 100%;
  width: 100%;
  margin-top: -4rem;
  margin-left: 2rem; */

  position: absolute;
  top: -2rem;
  left: 5rem;
  height: 75%;
  width: 75%;
}

/* .newsignup .rightCol {
  padding: 0rem 2rem 2rem 2rem;
} */

.newsignup .form__wrapper {
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.newsignup .form__wrapper .form__group {
  flex-basis: 48%;
  display: flex;
  flex-direction: column;
}

.newsignup .form__group {
  width: 98%;
  display: flex;
  flex-direction: column;
}

.newsignup .form__group > label {
  padding-block: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(117, 111, 134, 1);
}
.newsignup .form__select .MuiInputBase-input {
  height: 2.4rem;
}
.newsignup .buttonGroup {
  width: 98%;
  text-align: center;
  margin-top: 2rem;
}

.newsignup .buttonGroup .buttonGroup__button {
  background: rgba(150, 39, 234, 1);
  color: #fff;
  border-radius: 2rem;
  height: 3rem;
  width: 8rem;
}

.newsignup .googleMsg {
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  text-align: center;
  position: relative;
}

.newsignup .googleMsg::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 10rem;
  background-color: rgba(112, 112, 112, 0.502);
  top: 50%;
  left: 0.5rem;
}

.newsignup .googleMsg::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 10rem;
  background-color: rgba(136, 9, 9, 0.502);
  top: 50%;
  right: 0.25rem;
}

.newsignup .googleicn {
  height: 3rem;
  width: 4.2rem;
  border: 1px solid rgba(112, 112, 112, 0.502);
  margin: auto;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;
}

.newsignup .googleicn > img {
  height: 100%;
  width: 100%;
}

/* new update */

.newsignup .MuiFilledInput-input {
  padding: 18px 12px 16px !important;
}

.newsignup .MuiFilledInput-root,
.newsignup .MuiFilledInput-root:hover {
  background-color: #8ecfe21f !important;
  border-radius: 0.4rem;
}

.newsignup .MuiFilledInput-underline:before {
  border-bottom: unset !important;
}
.newsignup .MuiFilledInput-underline:hover:before {
  border-bottom: unset !important;
}

@media (min-width: 1280px) {
  .newsignup .rightCol {
    padding: 0rem 4rem 2rem 2rem;
  }
  .newsignup .newsignup__container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 1279px) and (min-width: 960px) {
  .newsignup .rightCol {
    padding: 0rem 2rem 2rem 2rem;
  }
  .newsignup .newsignup__container {
    margin-top: 2rem;
  }

  .newsignup .googleMsg::before,
  .newsignup .googleMsg::after {
    width: 7rem;
  }
}

@media (max-width: 768px) {
  .newsignup .leftCol {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .newsignup .newsignup__container {
    flex-direction: column;
  }

  .newsignup .leftCol__title > h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .newsignup .leftCol__imgContainer {
    padding-top: 0;
  }
  .newsignup .leftCol__imgWrapper {
    height: 18rem;
    width: 18rem;
  }

  /* .newsignup .leftCol__imgWrapper > img {
    margin-top: -2rem;
  } */
  .newsignup .img__shadow {
    position: absolute;
    top: 45%;
    left: 35%;
    height: 3rem;
    width: 6rem;
    border-radius: 50%;
    box-shadow: 1px 1px 4rem 20px #9627ea;
  }
  .newsignup .leftCol__imgWrapper > img {
    top: 0;
    left: 4rem;
  }
}

.newsignup .signUp--hidesm,
.newsignup .joinEvent--sm {
  display: none;
}

@media (max-width: 600px) {
  .newsignup .leftCol__title > h1 {
    font-size: 2rem;
    line-height: 4rem;
  }

  .newsignup .hide--sm {
    display: none;
  }

  .newsignup .newSignup__list {
    justify-content: center;
  }
  .newsignup .leftCol {
    display: none;
  }
  .newsignup .rightCol {
    flex-basis: 100%;
    padding-inline: 0.5rem;
  }
  .newsignup .newSignup__listItem {
    text-align: center;
  }
  .newsignup .signUp--hidesm {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  .newsignup .joinEvent--sm {
    display: block;
    text-align: center;
    margin-block: 1rem;
  }

  .newsignup .signUp--hidesm,
  .newsignup .joinEvent--sm {
    display: block;
  }

  .newsignup .joinEvent--sm .btnGroup__btn {
    border-radius: 2rem;
  }
  .newsignup .googleMsg::before,
  .newsignup .googleMsg::after {
    width: 5rem;
  }
}

.newLogin {
  position: relative;
  background-color: rgba(245, 245, 245, 1);
  min-height: 100vh;
}
.newLogin *,
.newLogin *::after,
.newLogin *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-bottom: none;
}

.newLogin .header {
  padding: 1rem 2rem;
}

.newLogin .header__list {
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 100%;
}
.newLogin .header__list > .brand {
  font-size: 1.5rem;
  color: var(--primary);
  font-weight: bold;
}

.newLogin .header__list > .btnGroup {
  margin-left: auto;
}

.newLogin .btnGroup > .btnGroup__btn {
  /* font-size: 1rem; */
  border-radius: 2rem;
}

.newLogin .login__btn {
  border-radius: 2rem;
}
.newLogin .landing__imgWrapper {
  max-width: 16rem;
  max-height: 16rem;
  /* margin-inline: auto; */
  margin-left: auto;
  margin-right: auto;
}

.newLogin .landing__imgWrapper > img {
  height: 100%;
  width: 100%;
}

.newLogin .formGroup {
  max-width: 28rem;
  /* margin-inline: auto; */
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}

.newLogin .inputGroup {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.newLogin .inputGroup > label {
  padding-block: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(117, 111, 134, 1);
}

.newLogin .formGroup__form .btnGroup {
  text-align: center;
  margin: 1rem 0;
  width: 100%;
}

.newLogin .inputGroup__input {
  background-color: #8ecfe21f !important;
  border-radius: 0.4rem;
}
.newLogin .MuiFilledInput-input {
  padding: 18px 12px 16px !important;
}

.newLogin .MuiFilledInput-root,
.newLogin .MuiFilledInput-root:hover {
  background-color: unset !important;
}

.MuiFilledInput-adornedEnd {
  padding-right: 0.3rem !important;
}

.newLogin .inputGroup__input .MuiFilledInput-underline:before {
  border-bottom: unset !important;
}
.newLogin .inputGroup__input .MuiFilledInput-underline:hover:before {
  border-bottom: unset !important;
}

.newLogin__opt {
  font-size: 1rem;
  font-weight: bold;
}
.newLogin__opt span {
  color: var(--primary);
  cursor: pointer;
}
.newLogin__opt2 {
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}
.newLogin__opt2 span {
  color: var(--primary);
  cursor: pointer;
}

.newLogin .loginOpt {
  text-align: center;
  margin-top: 0.4rem;
  /* font-size: 1rem; */
  font-weight: bold;
}

.newLogin .loginOpt > span,
.newLogin .loginOpt > a {
  color: var(--primary);
  cursor: pointer;
}

.newLogin .link__container {
  width: 100%;
  margin-top: 0.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.newLogin .googleMsg {
  text-align: center;
  margin-block: 1.5rem;
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  position: relative;
}

.newLogin .googleMsg::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 8rem;
  background-color: rgba(112, 112, 112, 0.502);
  top: 50%;
  left: 0;
}

.newLogin .googleMsg::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 8rem;
  background-color: rgba(112, 112, 112, 0.502);
  top: 50%;
  right: 0;
}

.newLogin .googleicn {
  height: 3rem;
  width: 4.2rem;
  border: 1px solid rgba(112, 112, 112, 0.502);
  margin: auto;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-block: 0.5rem;
}

.newLogin .googleicn > img {
  height: 100%;
  width: 100%;
}

.newLogin .signupDiv {
  height: 50%;
  width: 28%;
  position: absolute;
  left: 0;
  bottom: 0;
  /* border: 2px solid red; */
  overflow: hidden;
}

.newLogin .signupDiv > .signupDiv__circle {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.newLogin #main__circle {
  height: 111%;
  width: 111%;
  border-radius: 50%;
  position: absolute;
  left: -1rem;
  box-shadow: inset 0 0 55px 7px white;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2rem;
}

.newLogin .signupDiv__msg {
  font-size: 1.25rem;
  line-height: 1.2rem;
  text-shadow: 1px 1px 5rem var(--primary);
}

.newLogin .signupDiv__msg > .loginOpt {
  text-align: left;
  margin-top: 2rem;
}

.newLogin .signupDiv--sm,
.newLogin .joinEvent--sm {
  display: none;
}

.newLogin .forgotPass {
  color: rgba(117, 111, 134, 1);
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
}

/* ////////media queries////////// */
@media (min-width: 1280px) {
  .newLogin #main__circle {
    left: -3rem;
  }
}
@media (max-width: 960px) {
  .signupDiv {
    display: none;
  }
  .newLogin .loginOpt {
    font-size: 1.2rem;
  }
  .newLogin .signupDiv--sm {
    display: block;
    margin-bottom: 1rem;
  }
}
@media (max-width: 600px) {
  .newLogin .hide--sm {
    display: none;
  }
  .newLogin .header__list {
    justify-content: center;
  }
  .newLogin .header__list > .brand {
    font-size: 2rem;
  }
  .newLogin .landing__imgWrapper {
    max-width: 14rem;
    max-height: 14rem;
  }

  .formGroup {
    padding-inline: 1rem;
  }

  .signupDiv {
    display: none;
  }

  .newLogin .googleMsg::after,
  .newLogin .googleMsg::before {
    width: 4rem;
  }

  .newLogin .loginOpt {
    font-size: 1rem;
  }
  .newLogin .signupDiv--sm {
    display: block;
  }
  .newLogin .btnGroup__btn {
    margin: 1rem auto;
    text-align: center;
    border-radius: 2rem;
  }

  .newLogin .joinEvent--sm {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newLogin {
    padding-inline: 5px;
  }
  .newLogin .inputGroup > label {
    font-size: 0.87rem;
  }
}

@media (max-width: 768px) {
  .regAttendee_eventTicketInfo__3cZxu {
    width: 100vw;
    padding: 20px 15px;
  }

  .regAttendee_flexContainer__4UVcT {
    flex-direction: column;
    align-items: center;
  }

  .regAttendee_dateContainer__gv0gG,
  .regAttendee_feeContainer__1grVN {
    width: 100%;
    margin-bottom: 20px;
  }
}

.regAttendee_regAttendeeMain__a3awS {
  padding: 0;
  margin: 0;
}
.regAttendee_nav__2elgT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  width: 100%;
  background: #ffffff;
  position: sticky;
  top: 0;
}
.regAttendee_logo__1L9Hi {
  display: flex;
  align-items: center;
}
.regAttendee_header__2NM_0 {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  /* margin: 1rem; */
}
.regAttendee_header__2NM_0 .regAttendee_navItem__dmT2Y {
  text-decoration: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.9);
}
.regAttendee_menuIcon__3KnfH {
  display: none;
}
.regAttendee_drwerHeader__2xvRi {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  width: 200px;
}
.regAttendee_drwerHeader__2xvRi a {
  color: #000000;
}
.regAttendee_drawerIcon__ckVUM {
  /* border-bottom: 1px solid grey; */
  border-bottom-width: 100%;
  box-shadow: 7px 0px 10px rgba(40, 40, 40, 0.25);
  padding: 0.5rem;
}
.regAttendee_mainHeader__2h_RY {
  display: flex;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 1rem 0;
  justify-content: space-between;
}
.regAttendee_leftHeader__2eDMe {
  flex-basis: 50%;
  padding-left: 1.5rem;
}
.regAttendee_leftHeader__2eDMe h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0.02em;

  color: #000000;
}
.regAttendee_leftHeader__2eDMe p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;

  color: #666666;
}
.regAttendee_rightImg__3lEd5 {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
}
/* ************Agenda Section****************** */
.regAttendee_agenda__3XbqN {
  margin-top: 2rem;
}
.regAttendee_agendaTimeline__piEAc {
  max-width: 700px;
  margin: 0 auto;
}
.regAttendee_agendatimelineBody__33cCC h4 {
  margin: 0 0 0 22%;
  border-radius: 4px;
  padding: 0.5rem;
  color: #ffffff;
}

.regAttendee_timeNlocation__1DJFY {
  flex-basis: 30%;
  padding-top: 1rem;
}
.regAttendee_speakerDetail__3WsmX h1 {
  cursor: pointer;
  font-size: 17px;
  color: #393938;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}
.regAttendee_speakerDetail__3WsmX p {
  font-size: 14px;
  color: #393938;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}
.regAttendee_agendaSpeaker__3aHVJ {
  display: flex;
  margin: 2.5rem 0;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.regAttendee_agendaSpeaker__3aHVJ img {
  border-radius: 50%;
}
/* .timelineItem h6{
  position: relative;
} */
.regAttendee_timelineItem__eUOsC {
  display: flex;
}
.regAttendee_time__2B4nL {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(91, 89, 89);
  font-size: 16px;
  font-weight: bold;
}
.regAttendee_time__2B4nL p {
  word-wrap: break-word;
  margin: 0;
}
.regAttendee_location__3vc04 {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  color: #777;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.regAttendee_agendaTitle__3UvcS {
  display: flex;
  align-items: center;
}
.regAttendee_agendaTitle__3UvcS h2 {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  box-sizing: border-box;
  padding: 1rem;
  cursor: pointer;
}
.regAttendee_content__1G8nS {
  padding-left: 40px;
  padding-bottom: 40px;
  flex-basis: 70%;
  border-left: 3px solid rgb(91, 89, 89);
  padding-top: 1rem;
}
.regAttendee_agdModaltime__TzAgl {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: rgb(91, 89, 89);
  font-size: 13px;
  font-weight: bold;
  padding: 1rem;
  border-radius: 8px;
  height: 3rem;
  /* background-color: #ffffff !important; */
}
.regAttendee_agdModaltime__TzAgl p {
  white-space: nowrap;
}
/* ***********Speaker Section*************** */
.regAttendee_speakerSection__1xoRm {
  margin: 2rem 5rem;
}
.regAttendee_speakerSection__1xoRm h1 {
  text-align: center;
  margin-bottom: 2rem;
}
.regAttendee_SpeakerImg__1aebo {
  display: flex;
  justify-content: space-evenly;
  grid-gap: 2rem;
  gap: 2rem;
  /* grid-template-columns: repeat(auto-fit,minmax(350px,3fr));   */
  flex-wrap: wrap;
}
.regAttendee_img__2yuNK {
  position: relative;
  justify-content: center;
  align-items: center;
}
.regAttendee_detail__2_K2H {
  overflow-x: scroll;
  position: absolute;
  left: 14px;
  bottom: 15px;
  align-items: center;
  width: 270px;
  height: 70px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.regAttendee_detail__2_K2H::-webkit-scrollbar {
  display: none;
}
.regAttendee_detail__2_K2H p {
  font-family: 'Montserrat';
  font-style: normal;
  color: #000000;
  text-align: center;
  margin: 0;
}

/* ***********Sponsor Area************ */
.regAttendee_sponsorArea__i-8zE {
  margin: 2rem 0;
  padding: 2rem 1rem;
}
.regAttendee_sponsorArea__i-8zE h1 {
  text-align: center;
  margin: 2rem 0;
}
.regAttendee_sponsors__1LMSN {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
}
.regAttendee_sponsors__1LMSN img {
  width: 100px;
  /* height:100px ; */
}
/* ********Style for Dialog*********** */
.regAttendee_ModalTitle__swcoW {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  /* background-color: #9627ea; */
}
.regAttendee_ModalTitle__swcoW h1 {
  color: white;
  font-size: 1.5rem;
  margin: 0;
}
.regAttendee_ModalTitle__swcoW p {
  padding: 1rem;
}
.regAttendee_description__3Y04w {
  padding: 1rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: initial;
}
.regAttendee_speakerDesc__3Jr3T h1 {
  font-size: 17px;
  font-weight: 800;
  /* margin-bottom: 3px; */
}
.regAttendee_speakerDesc__3Jr3T p {
  font-size: 15px;
  font-weight: 400;
}
/* **********Enter Email Section********** */
.regAttendee_EnterEmail__2W6qI {
  max-width: 800px;
  margin: auto;
}
.regAttendee_emailInput__1boU0 h3 {
  padding: 1rem 0;
}
.regAttendee_btn__2rVVb {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

/* *****Style Starts for Register.js*********** */
.regAttendee_gotoTicket__2AgSg {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.regAttendee_regNav__nlixe {
  padding: 1.5rem 0;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.regAttendee_leftSide__3BJLO {
  width: 100%;
  flex-basis: 50%;
  height: 100%;
}
.regAttendee_eventTicketInfo__3cZxu {
  background: #eaedfa;
  border-radius: 30px;
  padding: 2rem;
}

.regAttendee_eventTicketInfo__3cZxu img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
}

.regAttendee_eventTicketInfo__3cZxu h1 {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-top: 1rem;
}
.regAttendee_About__2LIYS h3 {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-top: 2rem;
  color: #000000;
}
.regAttendee_About__2LIYS p {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-top: 0.5rem;
  /* color: #666666; */
}
.regAttendee_timeTable__2UDbd {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.regAttendee_timeTable__2UDbd p,
.regAttendee_timeTable__2UDbd h1 {
  font-family: 'Montserrat' !important;
  font-style: normal;
  margin: 0.5rem 0;
  text-align: center;
}
.regAttendee_timeTable__2UDbd h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}
.regAttendee_timeTable__2UDbd p {
  font-weight: 300;
  font-size: 16px;
  line-height: 34px;
  color: #726666;
}
.regAttendee_rightSide__JVnsd {
  width: 100%;
  flex-basis: 50%;
}
.regAttendee_rightSide__JVnsd h2 {
  font-weight: 500;
  margin: 2rem 0 1rem 0;
}
.regAttendee_rightSide__JVnsd h3 {
  font-weight: 500;
  font-size: 1rem;
  color: #666666;
  margin: 1rem 0 0.5rem 0;
}
.regAttendee_rightSide__JVnsd h5 {
  font-weight: 600;
  font-size: 1rem;
  margin: 1rem 0 0.75rem 0;
  color: #666666;
}
.regAttendee_radioSelect__G7aXr {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 0.5rem 0;
}
/* .radioSelect input{
  accent-color:#9627ea;
} */
.regAttendee_payNregBtn__1d6_c {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  grid-gap: 1rem;
  gap: 1rem;
}
.regAttendee_PaymentArea___-OBg h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}
.regAttendee_PaymentMode__hN5T9 {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 3rem 0;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
}
.regAttendee_PaymentMode__hN5T9:hover {
  box-shadow: inset 0px 0px 15px 5px rgba(128, 128, 128, 0.25);
}
.regAttendee_PaymentBtn__2sGSF {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.regAttendee_regModalEmail__1ku4j {
  padding: 1rem;
}
.regAttendee_regModalButton__184CG {
  display: flex;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.regAttendee_ExhibitorBox__BXzVN {
  min-height: 100%;
  border-radius: 8px;

  object-fit: cover;
}

.regAttendee_AttendeeExhibitorMain__3vIu_ {
  width: 268px;
  position: relative;
  min-height: 200px;
}

.regAttendee_ExhiDetails__3uBQl {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}
.regAttendee_allExhibitors__Sd4HA {
  margin: 2rem;
}
.regAttendee_allExhibitors__Sd4HA h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 22px;
}
.regAttendee_DetailBox__36iQ1 {
  display: flex;
  flex-direction: column;
  /* color: #fff; */
  /* opacity: 0.85; */
  padding: 0px 8px;
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 180px;
}
.regAttendee_TitleBox__3fCUr {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0.85;
  padding: 0px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.regAttendee_exhibitorCompany__3L27A {
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase !important;
  margin-left: -8px;
}
.regAttendee_exhibitorCategory__3u2Xe {
  text-overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
}
/* *********Media query for RegAttendee/index.js page************** */
@media only screen and (max-width: 1199px) {
  .regAttendee_mainHeader__2h_RY {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    grid-gap: 3rem;
    gap: 3rem;
    /* margin-top: 3rem; */
    /* padding: 2rem; */
  }
  .regAttendee_rightImg__3lEd5,
  .regAttendee_rightImg__3lEd5 img {
    width: 100%;
  }
  .regAttendee_leftHeader__2eDMe {
    padding-left: 0;
  }

  .regAttendee_header__2NM_0 .regAttendee_navItem__dmT2Y {
    display: none;
  }
  .regAttendee_menuIcon__3KnfH {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .regAttendee_timelineItem__eUOsC {
    display: flex;
    flex-direction: column;
  }
  .regAttendee_timeNlocation__1DJFY {
    margin-left: 2.5rem;
  }
  .regAttendee_content__1G8nS {
    border: none;
    margin-right: 2rem;
  }
  .regAttendee_agendatimelineBody__33cCC h4 {
    margin-left: 2.5rem;
  }
}
@media only screen and (min-width: 1199px) {
  .regAttendee_RegMain__1cubw {
    display: flex;
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem 0;
  }
}
/* ********Media query for Register.js page********** */
@media only screen and (max-width: 1199px) {
  .regAttendee_RegMain__1cubw {
    padding: 1rem 0;
  }
  .regAttendee_leftSide__3BJLO {
    flex-basis: 100%;
  }
  .regAttendee_rightSide__JVnsd {
    padding-left: 0;
    flex-basis: 100%;
  }
}
@media (max-width: 600px) {
  .regAttendee_eventTicketInfo__3cZxu {
    padding: 1rem;
  }
}

/* *******************Styles start for ChooseTicket.js**************** */

.regAttendee_firstContainer__Ubpw- {
  padding: 2rem;
  background: #eaedfa;
  border-radius: 2rem;
  margin: 1rem 2rem;
}
.regAttendee_firstContainer__Ubpw- h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;

  color: #9627ea;
}
.regAttendee_eventDetail__3tU05 {
  display: flex;
  justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem;
}

.regAttendee_evDetailLeft__ExHGu {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  flex-basis: 85%;
}
.regAttendee_evDetailLeft__ExHGu img {
  width: 342px;
  height: 230px;
  border-radius: 20px;
}
.regAttendee_evDates__270HC {
  flex-basis: 15%;
  display: grid;
  /* flex-direction: column; */
  grid-gap: 1rem;
  gap: 1rem;
}
.regAttendee_evDates__270HC h2 {
  font-size: 20px;
}

/* ************************ */
.regAttendee_secContainer__19wu2 {
  display: flex;
  grid-gap: 3rem;
  gap: 3rem;
  margin: 2rem 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.regAttendee_ticketCard__3ddls {
  background: #ffffff;
  border: 2px solid rgba(128, 128, 128, 0.25);
  border-radius: 20px;
  padding: 0 1rem 1rem 1rem;
  width: 45%;
  cursor: pointer;
}
.regAttendee_ticketCard__3ddls:hover {
  box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.25);
}
.regAttendee_ticketCard__3ddls p {
  font-size: 14px;
}
/* .active{
  box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.25);
  border-color: greenyellow;
} */
.regAttendee_cardHeader__Sm30E {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.regAttendee_cardHeader__Sm30E h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #9627ea;
}
.regAttendee_cardHeader__Sm30E h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
}
.regAttendee_ticketBtn__3IIpd {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.regAttendee_ticketDates__1zmUG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/* *******media query for ChooseTicket.js************** */
@media only screen and (max-width: 1199px) {
  .regAttendee_firstContainer__Ubpw- {
    padding: 2rem;
  }
  .regAttendee_eventDetail__3tU05 {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .regAttendee_evDates__270HC {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 900px) {
  .regAttendee_evDetailLeft__ExHGu {
    display: flex;
    flex-direction: column;
  }
  .regAttendee_evDetailLeft__ExHGu img {
    width: 100%;
  }
  .regAttendee_eventDetail__3tU05 {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .regAttendee_evDates__270HC {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 760px) {
  .regAttendee_firstContainer__Ubpw- {
    margin: 0;
    padding: 1rem;
  }
  .regAttendee_secContainer__19wu2 {
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin: 2rem 0;
  }
  .regAttendee_ticketCard__3ddls {
    width: 100%;
  }
}

/* ***********Styles starts for RegSuccess.js page****************** */
.regAttendee_successMain__3Itys {
  display: grid;
  justify-content: center;
}
.regAttendee_successImage__1xTfy {
  display: flex;
  justify-content: center;
}
.regAttendee_successMain__3Itys h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #000000;
}
.regAttendee_successMain__3Itys p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #000000;
}
.regAttendee_successDetail__SS9tk {
  background: #eaedfa;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  border-radius: 2rem;
  padding: 2rem;
  margin: 2rem;
}
.regAttendee_successDetail__SS9tk img {
  width: 100%;
  border-radius: 1.5rem;
}
.regAttendee_successDates__1Vqz6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}
.regAttendee_successAbout__36Wtp h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #6f319d;
}
.regAttendee_successAbout__36Wtp h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: center;

  color: #000000;
}
.regAttendee_successStatrtDate__eUAE6 h4 {
  margin: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;

  color: #000000;
}
.regAttendee_successStatrtDate__eUAE6 h6 {
  margin: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #726666;
}
@media (max-width: 600px) {
  .regAttendee_successDetail__SS9tk {
    padding: 1rem;
  }
}

/* *************Styles for MySchedules.js************* */
.regAttendee_FavParent__2fB9B {
  text-align: center;
}
.regAttendee_ExportPdfBtn__2hGa_ {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
}

.regAttendee_spkImg__3vfcj {
  position: relative;
  justify-content: center;
  align-items: center;
}

.regAttendee_details__z0Cnj {
  overflow-x: scroll;
  position: absolute;
  left: 12px;
  bottom: 10px;
  align-items: center;
  width: 200px;
  height: 60px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 0.15rem;
}
.regAttendee_details__z0Cnj::-webkit-scrollbar {
  display: none;
}
.regAttendee_details__z0Cnj h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding: 1rem;
  color: #000000;
}
.regAttendee_nameSocialLinks__wg5uj {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  /* align-items: center; */
  /* padding: 0.2rem; */
}
.regAttendee_details__z0Cnj p {
  margin: 0;
  text-align: center;
  font-size: 13px;
}

/* Add this to your Style (or styles, depending on the exact name) */
.regAttendee_spkImgContainer__1Q5-B {
  position: relative;
  cursor: pointer;
}

.regAttendee_overlay__2bbr2 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  color: white;
  text-align: center;
  padding: 10px;
}

.spotregister_registrationPanel__36FaD {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* This will left-align the children */
  width: 100%; /* Make sure it spans the full width */
  padding: 2rem; /* Padding on all sides, adjust as needed */
}

.resetPassword.loginMsg {
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
}

.resetPassword.loginRoute {
  text-decoration: underline;
}

.mobileHeader {
  padding: 0.6rem 0.875rem 0.6rem 0.2rem;
  margin: 0rem;
  background-color: white;
  display: none;
  position: sticky;
  top: 0rem;
  z-index: 3;
}

.mobileHeader .mobileHeader__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileHeader .mobileHeader__title .title {
  color: #000;
  margin: 0rem;
  text-transform: capitalize;
  font-size: 1.3rem;
}

.mobileHeader .mobileHeader__avatar .profileImg {
  height: 100%;
  width: 100%;
}

/* //// media query //////// */
@media (max-width: 768px) {
  .mobileHeader {
    display: block;
  }
}

@media (max-width: 350px) {
  .mobileHeader .mobileHeader__title .title {
    font-size: 1.2rem;
  }
}

.drawer {
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
  transition: 0.5s linear;
}

.drawer.hideDrawer {
  background-color: transparent;
  left: -100rem;
  transition: 0.5s linear;
}

.drawer__container {
  height: 100%;
  width: 80%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: white;
}

.drawer__header {
  border-bottom: 2px solid rgb(203 147 245);
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  background-color: rgba(150, 39, 234, 1);
  border-top-right-radius: 1rem;
}

.drawer__header .leftArrowIcon {
  background-color: white;
  border-radius: 50%;
}

.drawer__header p {
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.drawer__header .settingBtn {
  color: white;
  margin-left: auto;
}

.drawer__meeting {
  border-bottom-right-radius: 1rem;
  background-color: rgba(150, 39, 234, 1);
  padding: 0.8rem 1rem;
}

.drawer__meeting p {
  margin: 0 0 0.5rem 0rem;
  font-weight: bold;
  color: white;
  letter-spacing: 0.1rem;
}

.drawer__meeting span {
  color: white;
  font-size: 0.9rem;
}

.drawer__list {
  list-style: none;
  padding: 0rem;
  border-bottom: 2px solid rgba(117, 111, 134, 0.502);
  height: 56vh;
  overflow: auto;
  scrollbar-width: none;
}

.drawer__list::-webkit-scrollbar {
  display: none;
}

.drawer__list li {
  padding: 1rem 0.7rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.drawer__list li.menuActive {
  color: rgba(150, 39, 234, 1);
  border-left: 0.3rem solid rgba(150, 39, 234, 1);
  background: rgba(244, 246, 250, 1);
}

.drawer__list li .drawer__linkName {
  margin-left: 0.5rem;
}

.drawer__about {
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 700;
}
.drawer__about a {
  color: rgba(117, 111, 134, 1);
}

.drawer__actions {
  display: flex;
  align-items: center;
}

.drawer__actions .ml-auto {
  margin-left: auto;
}

.modal__fade--sm {
  width: 90% !important;
}

@media (max-width: 350px) {
  .drawer__list {
    height: 49vh;
  }
}

@media (min-width: 770px) {
  .drawer {
    display: none;
  }
}

body {
  background: #f4f6fa;
}

/* stream home page */
.stream_leftSideBar__1ryo5 {
  background-color: white;
}

.stream_sideBarHeader__3zcyg {
  height: 5rem;
  padding: 0.5rem 0.75rem;
}
.stream_sideBarLinks__YwQCT {
  height: calc(100vh - 5rem);
  overflow: auto;
  padding: 0.5rem;
}
.stream_sidebarOption__NXM1i {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  margin: 0.5rem;
  text-align: center;
  font-size: 12px;
  color: #555;
  font-weight: bold;
}

.stream_sidebarOption__NXM1i h2 {
  margin: 0;
}
.stream_sidebarOption__NXM1i span {
  font-size: 9px;
}

.stream_main__2jBt8 {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: #f4f6fa;
}
.stream_streamHeaderBox__320mr {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.stream_streamHeaderBox__320mr h1,
.stream_streamHeaderBox__320mr h2 {
  margin: 0;
  font-weight: 600;
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.stream_streamHeaderBox__320mr h1::after {
  content: '';
  flex: 1 1;
  margin-left: 1rem;
  height: 2px;
  background-color: #aaaaaa;
}
.stream_streamHeaderBox__320mr .stream_icon__3kLpY {
  display: flex;
  font-size: 2.5rem;
  align-items: center;
}
@media (min-width: 1200px) {
  .stream_streamHeaderBox__320mr,
  .stream_streamContent__1g2GT {
    margin: 0 1rem;
  }
}
.stream_gridParentBox__2xaOl {
  width: 100%;
  background: #f4f6fa;
}
.stream_sponsorCardLeft__2D1Mr {
  /* max-height: 27.063rem; */
  position: relative;
}
.stream_sponsorCardLeft__2D1Mr img {
  border-radius: 10px;
  /* height: 400px; */
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}
.stream_sponsorCardLeft__2D1Mr .stream_addToCalendar__2hpBA {
  border-radius: 25px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.stream_sponsorCardRight__1Y6Fk {
  /* max-height: 27.063rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: white;
  /* padding: 0.75rem; */
}
.stream_sponsorLogo__2E96h {
  display: flex;
  padding: 0.375rem;
  justify-content: center;
}
.stream_host__3FYq2 {
  text-align: center;
}
.stream_host__3FYq2 p {
  margin: 0;
  padding-top: 0.25rem;
}
.stream_host__3FYq2 h2 {
  margin: 0.25rem 0;
  font-weight: 600;
}
.stream_host__3FYq2 h4 {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
.stream_shareOn__2D37B {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  padding: 0.75rem 0;
  font-weight: 600;
  color: #737182;
}
.stream_social__3lJc6 {
  display: flex;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.stream_agendaSpeaker__1WXPl img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

/* stream login page */
.stream_loginParent__3sjsP {
  height: 100vh;
}
.stream_leftCol__2vaSq {
  display: flex;
  margin: auto;
  width: 100%;
  height: 60%;
  background-color: #9627ea;
}


/* .loginContent h2,
p {
  color: white;
} */
.stream_bannerParent__1B-bx {
  width: 100%;
  padding: 2rem 1rem;
}
.stream_bannerParent__1B-bx img {
  width: 100%;
  border-radius: 1.625rem;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 25%);
  border: 0.1px solid #fff;
  max-width: 38.125rem;
  margin: auto;
}
/* speaker page */
.stream_streamSpeakersParentBox__1bfIX {
  background-color: #f4f6fa;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
}
.stream_speakerSession__2M_WH {
  border: 1px solid #a6a4ad;
  border-radius: 25px;
  background-color: #f2f6f9;
  margin-top: 1rem;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 1rem;
}
.stream_speakerSession__2M_WH h4,
.stream_speakerSession__2M_WH h6 {
  margin: 0;
}
.stream_speakerPhoto__3MCsi {
  width: 100%;
  object-fit: cover;
}
.stream_speakerNameBox__2dAtg {
  background-color: #f4f6fa;
  font-size: 20px;
  border: 1px solid transparent;
  border-bottom: 1px solid #cac8c8;
  text-align: left;
  vertical-align: bottom;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  opacity: 0.8;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stream_designationBox__1GlBn {
  background-color: #f4f6fa;
  font-family: 'Montserrat';
  font-size: 0.938rem;
  align-items: flex-end;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  margin-bottom: 0px;
  border: 1px solid transparent;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  height: 1.563rem;
  text-align: left;
  vertical-align: bottom;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
}
.stream_designationBox__1GlBn p {
  text-overflow: clip;
}

.stream_SponsorImg__3Uu-e {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}
.stream_speakerGridBox__1JzMs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
}
.stream_speakerGrid__1eF2p {
  border-radius: 20px;
  border: 5px solid #fff;
  padding-left: 0rem !important;
  margin-bottom: 6rem;
  margin-top: 6rem;
}
.stream_speakerGrid__1eF2p img {
  width: 100%;
  object-fit: cover;
}
.stream_speakerSection__1RRJP {
  margin: 1rem;
}

.stream_SpeakerImg__2JrN5 {
  display: flex;
  justify-content: space-evenly;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.stream_spkImg__Dx1aA {
  position: relative;
  justify-content: center;
  align-items: center;
}
.stream_details__2zgkN {
  overflow-x: scroll;
  position: absolute;
  left: 12px;
  bottom: 10px;
  align-items: center;
  width: 200px;
  height: 60px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 0.15rem;
}
.stream_details__2zgkN::-webkit-scrollbar {
  display: none;
}
.stream_details__2zgkN h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding: 1rem;
  color: #000000;
}
.stream_nameSocialLinks__Ezr_d {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  /* align-items: center; */
  /* padding: 0.2rem; */
}
.stream_details__2zgkN p {
  margin: 0;
  text-align: center;
  font-size: 13px;
}
/* streamAgenda page */
.stream_streamAgendaParentBox__1jC2q,
.stream_streamAboutParentBox__3WsB_,
.stream_streamDocumentParentBox__YSZj- {
  width: 100%;
  background: #f4f6fa;
  display: flex;
}
.stream_streamVideoParentBox__3hlwK {
  display: flex;
}
.stream_agendaScheduleBtn__qr13c {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
}
.stream_agendaScheduleBtn__qr13c .stream_allBtn__10w3M {
  background: #ffffff;
  border-radius: 50px;
  border: 4px solid #eaedfa;
  cursor: pointer;
}
.stream_agendaScheduleBtn__qr13c .stream_agendaDateFilter__2oRjn {
  color: #ffffff !important;
  border: none !important;
  border-radius: 8px;
  font-size: 1rem;
}
.stream_agendaScheduleContainer__acavl {
  background: #ffffff;
  padding: 1.25rem;
  border-radius: 0.5rem;
}
.stream_agendaScheduleDate__18ued {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 700;
}
.stream_agendaScheduleContent__3LTqH {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  grid-gap: 2rem;
  gap: 2rem;
}
.stream_time__3ZfDS {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: rgb(91, 89, 89);
  font-size: 16px;
  font-weight: bold;
  padding: 1rem;
  border-radius: 8px;
  height: 3rem;
  /* background-color: #ffffff !important; */
}
.stream_time__3ZfDS p {
  white-space: nowrap;
}
.stream_topic__aKcza {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
.stream_agendaSpeakerDetails__2i_Rg {
  display: flex;
  margin: 1.4rem 0;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.stream_speakerName__2B41y {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.stream_speakerDetail__1x9Lh h1 {
  cursor: pointer;
  font-size: 17px;
  color: #393938;
  font-weight: bold;
  margin-bottom: 2px;
  font-family: 'Montserrat', sans-serif;
}
.stream_speakerDetail__1x9Lh .stream_speakerName__2B41y {
  color: #545454;
  font-size: 0.938rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-top: -2.4rem;
}
.stream_speakerDetail__1x9Lh .stream_speakerPosition__1bva8 {
  color: #808080;
  font-size: 0.938rem;
  margin-top: 0.625rem;
  white-space: wrap;
}

/* .joinSession {
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  min-width: 215px;
  padding: 12px 15px;
  margin-bottom: 10px;
} */
.stream_sessionLocationParent__QXQD1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 0.5rem;
  /* position: relative; */
}
.stream_sessionLocation__2Aj2M {
  background: #ffffff !important;
  border-radius: 10px;
}
.stream_speakerIconsParent__2g7dK {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 1rem; */
  /* position: absolute;
  right: 0; */
}
.stream_datePicker__210No {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.4rem;
  color: #ffffff;
  width: 6rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

/* stream sponsors page */

.stream_sponsorBannerBox__3E0LM {
  /* width: 30%; */
  height: auto;
  border-radius: 8px;
  position: relative;
  object-fit: cover;
}

.stream_sponsorLogoBox__YdWuq {
  position: absolute;
  left: 4px;
  top: 3px;
  margin: -0.8% -0.8%;
  padding: 2px;
  border-radius: 10px;
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}
.stream_sponsorLogoBox__YdWuq img {
  padding: 5%;
  object-fit: cover;
  border-radius: 5px;
  width: 30px;
}
.stream_sponsorDetailBox__2RyDF {
  display: flex;
  flex-direction: column;
  background: #fff;
  opacity: 0.9;
  padding: 0px 15px;
  margin: 0px 4px;
  width: calc(100% + 6px);
  margin-bottom: -8px;
  margin-left: -3px;
}
.stream_sponsorCompanyName__3285B {
  text-overflow: hidden;
  overflow: hidden;
  padding: 1px 0px;
  padding-bottom: 4px;
  width: 470px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 1.563rem;
  color: #2c2738;
  text-transform: uppercase !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

/* Expanded sponsors page */
.stream_BannerParentBox__12hNb {
  /* width: 100%;
  margin: 2rem;
  display: flex;
  justify-content: flex-end;
  border-radius: 0.625rem;
  background-repeat: no-repeat;
  background-size: contain, cover; */
  background-size: cover;
  display: grid;
  grid-template-rows: 25em auto;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;
  position: relative;
  margin-top: 1rem;
}

.stream_SponsorCompanyLogoParent__1SNTv {
  background-size: cover;
  position: absolute;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 35%;
  box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
  /* height: 18.75rem; */
  height: -webkit-fill-available;
  -webkit-backdrop-filter: blur(14px);
          backdrop-filter: blur(14px);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
}
/* .SponsorCompanyLogoParent img {
  height: 40%;
} */
.stream_SponsorCompanyLogoParent__1SNTv h1 {
  font-size: 0.625rem;
}
.stream_ExhibitorBannerParentBox__l-ub_ {
  border-radius: 10px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 100%;

  /* position: relative; */
}
.stream_exiStreamVideo__23P_4 {
  /* position: absolute; */
  width: 100%;
  height: 100%;
}
.stream_ExhibitorCompanyLogoParent__1bjek img {
  width: 200px;
}
/* .exhibitorDetailMain{
  padding: 0 1rem;
} */
/* .exhibitorDetailMain p{
  line-height: 30px;
} */
.stream_meetingContactDocsParent__tJCKI {
  display: grid;
  margin: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}
.stream_meetingContactDocsParent__tJCKI .stream_meet__oIwmG {
  border-radius: 10px;
  border: 5px solid #fff;
  text-align: left;
  font-size: 30px;
  width: 100%;
  background-color: white;
  overflow: hidden;
}
.stream_meetingContactDocsParent__tJCKI .stream_meet__oIwmG #stream_bookNowBtn__23-9s {
  text-align: center;
}

.stream_meet__oIwmG h4 {
  font-size: 1.8rem;
  margin: 10px 0 0 0;
  font-weight: 600;
  text-align: center;
  flood-color: #9627ea;
  color: #9627ea;
}
.stream_meet__oIwmG p {
  margin-left: 10px;
  margin-right: 5px;
  font-size: 0.8rem;
  color: #756f86;
}
.stream_bookSlot__3oV2b {
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
}
.stream_contactDetl__bPQGH p {
  margin: 10px 10px 0 5px;
  font-size: 0.8rem;
  color: #756f86;
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}
.stream_meet__oIwmG .stream_bookMeetingDate__6tzz_ {
  display: grid;
  margin-left: 10px;
  margin-right: 10px;
  grid-template-columns: repeat(5, 1.5em);
  grid-template-rows: 3.5rem;
  cursor: pointer;
  grid-gap: 10px;
}
.stream_meet__oIwmG .stream_bookMeetingDate__6tzz_ div {
  border: 1px solid #756f86;
  font-size: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #756f86;
  border-radius: 5px;
}
.stream_meet__oIwmG .stream_bookMeetingDate__6tzz_ div:hover {
  color: white;
  background-color: #9627ea;
  border: none;
}

.stream_exhibitorCompany__1W-AJ {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 1px 0px 4px 1px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #2c2738;
  text-transform: uppercase !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

/* ****************Stream Video.js************ */
.stream_streamVideoContainer__3jh1j {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.stream_streamVideoContainer__3jh1j p {
  color: white;
  margin: 0;
}
.stream_docDetails__15Uc4,
.stream_vidDetails__1rSL1 {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin: -7px 0 0 0;
}
.stream_vidDetails__1rSL1 p {
  width: 240px;
  text-overflow: ellipsis;
}
.stream_videoTitle__35xny,
.stream_docTitle__1SXBF {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 2rem;
}
.stream_videoTitle__35xny h1,
.stream_docTitle__1SXBF h1 {
  font-size: 20px;
  margin: 0;
}

/* **********Styles for Survey Questions********** */
.stream_surveyMain__2yZN1 {
  padding: 2rem;
  margin: 0 2rem 2rem;
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 7px 10px 5px rgba(0, 0, 0, 0.25);
}
.stream_surveyBtn__3fXo8 {
  display: flex;
  justify-content: center;
  margin: 0 0 2rem;
}
.stream_menuIcon__3P35V {
  display: flex;
  color: #000;
}
/* streamLeaderboard  */
.stream_leaderBoardGrid__1j6kM {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  /* margin-top: 1rem; */
}
.stream_leaderBoardGrid__1j6kM .stream_leaderboardCard__2gl2H {
  border-radius: 25px;
  border: 6px solid #fff;
  text-align: center;
  font-size: 30px;
  background-size: 100% 100%;
  position: relative;
  box-shadow: 5px 10px 8px #ddd;
  margin: 1rem;
}
.stream_leaderboardCard__2gl2H .stream_medal__3yfy7 {
  position: absolute;
  right: 0rem;
  top: 0rem;
}
.stream_leaderboardCard__2gl2H .stream_leaderName__YdR12 {
  background-color: #fff;
  padding: 0.5rem;
  width: max-content;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  -webkit-text-emphasis: bold;
          text-emphasis: bold;
  font-size: 23px;
  float: center;
  font-weight: bolder;
  color: #8f8686;
  border: 1px solid transparent;
  border-radius: 100px;
  vertical-align: bottom;
  transform: translateX(-50%);
  box-shadow: 5px 10px 8px #ddd;
}
.stream_leaderboardResults__3BuZU .stream_resultElement__2-1Bu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  height: 90px;
  color: #8f8686;
  font-weight: bold;
  font-size: 25px;
  background-color: #fff;
  border: 5px solid white;
  margin-left: 10px;
}
.stream_leaderboardResults__3BuZU .stream_resultElement__2-1Bu img {
  max-width: 100% !important;
  height: 100%;
  object-fit: contain;
}
.stream_textMessage__31tgr{
  text-align: center;
  vertical-align: middle;
}
@media screen and (min-width: 600px) {
  .stream_loginParent__3sjsP {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .stream_leftCol__2vaSq {
    flex-basis: 50%;
    height:100%;
  }
  .stream_loginContent__3X203 {
    width: 80% !important;
  }
  .stream_bannerParent__1B-bx {
    flex-basis: 50%;
  }
  .stream_gridParentBox__2xaOl {
    width: calc(100% - 90px);
    margin-left: auto;
    display: flex;
  }
  .stream_speakerGridBox__1JzMs {
    width: calc(100% - 90px);
  }
  .stream_agendaScheduleBtn__qr13c {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .stream_agendaScheduleContent__3LTqH {
    display: flex;
    justify-content: flex-start;
    /* flex-wrap: wrap; */
    grid-gap: 2rem;
    gap: 2rem;
  }
  .stream_sponsorBannerBox__3E0LM {
    /* width: 31.25rem; */
    height: 15.625rem;
  }
  .stream_img__3sc76 {
    width: 48%;
   
  }
  .stream_SponsorCompanyLogoParent__1SNTv {
    width: 25%;
  }
  .stream_BannerParentBox__12hNb {
    margin: 2rem;
  }
  .stream_meetingContactDocsParent__tJCKI .stream_meet__oIwmG {
    width: 10em;
  }
  .stream_meetingContactDocsParent__tJCKI {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 3rem;
  }
  .stream_menuIcon__3P35V {
    display: none;
  }
  /* .main {
    width: calc(100% - 12.3rem);
  } */
}
@media (min-width: 758px) and (max-width: 1024px) {
  .stream_meetingContactDocsParent__tJCKI {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0.5rem;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .stream_menuIcon__3P35V {
    display: none;
  }
}

.streamRightSidebar_rSidebarItem__2O_pD {
  margin: 0.675rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.streamRightSidebar_SidebarPanel__2bBEb {
  position: relative;
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: #f4f6fa;
  height: calc(100vh - 6.5rem);
}

.streamRightSidebar_RSTextField__3m3cD {
  position: absolute !important;
  bottom: 0.75rem;
  left: 0.5rem;
  right: 0.5rem;
  background-color: white;
}
.streamRightSidebar_Badge__QGBmi {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: green;
  border: 2px solid white;
}

.streamRightSidebar_DiscussionMsg__1QBD5 {
  display: flex;
  /* align-items: center; */
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.75rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: white;
}
.streamRightSidebar_DiscussionMsgText__2oguF {
  flex-grow: 1;
  /* padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background-color: white; */
}

.streamRightSidebar_QnaList__2ZHaa {
  display: flex;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid #888;
}
.streamRightSidebar_QnaListHeader__3Kcq1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 0.125rem;
  gap: 0.125rem;
  margin-bottom: 0.125rem;
}

.streamRightSidebar_UserList__2j_a0 {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: white;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}
.streamRightSidebar_UserList__2j_a0:hover {
  background-color: #fcfdfd;
}
.streamRightSidebar_SelfChatRow__12JIo {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.streamRightSidebar_ChatRow__F-JTS {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.streamRightSidebar_ChatBubble__2c5e8 {
  position: relative;
  padding: 0.5rem 0.75rem;
  margin-left: 1.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.streamRightSidebar_ChatBubble__2c5e8::before {
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-left: 20px solid transparent;
  left: -20px;
  top: 0;
}
.streamRightSidebar_SelfChatBubble__39PPN {
  position: relative;
  padding: 0.5rem 0.75rem;
  margin-right: 1.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.streamRightSidebar_SelfChatBubble__39PPN::before {
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right: 20px solid transparent;
  right: -20px;
  top: 0;
}

.streamRightSidebar_Chats__1c2Oj {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  justify-content: space-between;
}
.streamRightSidebar_ChatHeader__2CPD5 {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.streamRightSidebar_SidebarListItem__2bOga {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.375rem 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  align-items: center;
  margin: 1rem 0;
}
.streamRightSidebar_ListItemTitle__3Aweu {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #444;
}
.streamRightSidebar_DateSeperator__2xfbN {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.streamRightSidebar_DateSeperator__2xfbN small {
  color: rgba(123, 102, 102, 0.4);
  white-space: nowrap;
}
.streamRightSidebar_DateSeperator__2xfbN span {
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.streamRightSidebar_time__2N0fd {
  color: rgba(123, 102, 102, 0.4);
  white-space: nowrap;
}

.quizVote {
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 0.5rem 1rem 0 1rem;
}

.quizVote span {
  margin: 0;
  word-break: break-word;
}

.quizBox {
  word-wrap: break-word;
}
.quizBox__title {
  margin: 0;
  font-size: 1rem;
  color: #7b6666;
}

.quizBox__list {
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.quizBox__listItem {
  border: 0.797452px solid rgba(128, 128, 128, 0.2);
  box-shadow: 0.797452px 0.797452px 3.18981px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-top: 0.5rem;
}

.quizBox__listItem--question {
  font-weight: bold;
  border: none;
  border-radius: 0;
  box-shadow: none;
  pointer-events: none;
}

.quizBox__listItem--question .quizQuestion {
  font-weight: bold;
  font-size: 1.25rem;
  color: #000;
}

.quizBox__image {
  height: 5.375rem;
  width: 5.625rem;
  align-self: flex-start;
}

.quizBox__image > img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  object-fit: cover;
}

.quizBox__vector {
  margin: auto;
}

.quizBox__vector > img {
  height: 100%;
  width: 100%;
}

.quizBox__option {
  font-size: 1.063rem;
  color: rgba(117, 111, 134, 1);
  font-weight: 600;
  padding: 0.25rem;
}
.quizBox__iconButton {
  border: 1px solid rgba(117, 111, 134, 1) !important;
  margin: 1rem !important;
}
.quizBox__iconButton--active {
  background-color: rebeccapurple !important;
}
.quizBox__iconButton--active:hover {
  background-color: rebeccapurple !important;
}
.quizBox__icon--active {
  color: #fff;
}
.quizBox__nav {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.quizBox__prevBtn,
.quizBox__nextBtn {
  background-color: rebeccapurple !important;
  height: 1.5rem;
  width: 1.5rem;
  margin-inline: 10px !important;
}
.quizBox__prevIcon,
.quizBox__nextIcon {
  color: white !important;
}

.quizBox__prevBtn:hover,
.quizBox__nextBtn:hover {
  background-color: rebeccapurple;
}
.quizBox__counter {
  color: rgba(117, 111, 134, 1);
  font-size: bold;
  font-weight: 500;
}
.quizBox__btnGroup {
  text-align: center;
  margin-top: 1.25rem;
}

.submitBtn {
  width: 100%;
}

.submitBtn--disabled {
  background: rgb(136, 136, 136) !important;
  color: white !important;
}

.quizVote .quizBox__lockStatus {
  color: #baba;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 500;
}

.quiz-timer {
  padding-block: 1.25rem;
  max-width: 50rem;
  margin: auto;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.quiz-timer .timer-progress-wrapper {
  height: 0.35rem;
  background-color: rgb(225, 225, 225);
  border-radius: 3rem;
  flex-basis: 80%;
}

.quiz-timer .timer-progress-bar {
  height: 100%;
  border-radius: 3rem;
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  transition-property: 'width';
}

.quiz-timer .timer-count {
  flex-basis: 20%;
  text-align: right;
  letter-spacing: 0.05rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: #7b6666 !important;
  min-width: 4.75rem;
  text-align: center;
}

.anonymous-voting-text {
  font-weight: 500;
  font-size: 1rem;
  color: #808080;
  line-height: 1.25rem;
  margin: 1rem 0 0.5rem 0;
}

.anonymous-voting-text span {
  font-weight: 600;
  color: #363636;
}

@media (max-width: 600px) {
  .quizVote * {
    font-size: 1rem !important;
    font-weight: 500;
  }
}

@media (min-width: 768px) {
  .quizBox__listItem--question .quizQuestion {
    font-size: 1.25rem;
  }
  .submitBtn {
    width: 7.625rem;
  }
  .quizBox__title {
    font-size: 1.2rem;
  }
}

.wordCloudVote p {
  padding: 0.45rem 0;
}
.wordCloudVote p small {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: gray;
}
.wordCloudVote-card {
  min-width: 304px;
  max-height: 75vh;
  overflow: auto;
  border: 1px solid lightgray;
  border-radius: 0.75rem;
  background-color: white;
  box-shadow: 0 0 10px lightgray;
}
.wordCloudVote-card .card-header {
  margin: 0;
  padding: 1rem;
}
.wordCloudOption-container {
  padding: 0 1rem;
}
.wordCloudVote-btn-container {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.wordCloudVote-btn-container button {
  color: white;
  padding: 5px 1.5rem;
  border-radius: 2rem !important;
}
.wordCloudVote-edit-res-btn {
  border-radius: 2rem !important;
  margin: 4px 0 !important;
  padding: 4px 1rem !important;
  background-color: white !important;
}

.wordCloudVote-option {
  list-style: none;
  margin-top: 8px;
  padding: 8px 1rem;
  background-color: #f5f5fa;
  border: 1px solid #eee;
  border-radius: 5px;
  font-weight: 600;
  color: gray;
  cursor: pointer;
}
.wordCloudVote-option.selected {
  background-color: var(--secondary) !important;
  border-color: var(--secondary);
  color: white;
}

.WCAddOption-input {
  margin-top: 9px;
}
.WCAddOption-input input {
  background-color: #f5f5fa;
  padding-top: 9px;
  padding-bottom: 9px;
}
.wordCloudVote-wc {
  text-align: center;
  padding: 1rem 0;
}
.vote-reactWordCloud {
  height: 250px;
}

@media (max-width: 600px) {
  /* .wordCloudVote-card {
    width: 100%;
  } */
  .vote-reactWordCloud {
    height: 220px;
  }
}

/* @media (min-width: 601px) and (max-width: 1024px) {
  .wordCloudVote-card {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
} */

.ratingVote {
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 0 1rem;
}

.ratingVote p {
  padding: 0.45rem 0;
}
.ratingVote p small {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: gray;
}
.ratingVote-card {
  min-width: 304px;
  max-width: 600px;
  border: 1px solid lightgray;
  border-radius: 0.75rem;
  background-color: white;
  box-shadow: 0 0 10px lightgray;
}

.ratingVote .card-header {
  padding: 1rem;
  word-wrap: break-word;
  margin: 0;
}
.rating-container {
  text-align: center;
  padding: 0.5rem 0;
}
.rating-container h4 {
  padding: 0.5rem 0;
}
.ratingVote-btn-container {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.ratingVote-btn-container button {
  color: white;
  padding: 5px 1.5rem;
  border-radius: 2rem !important;
}
.ratingVote-edit-res-btn {
  border-radius: 2rem !important;
  margin: 4px 0 !important;
  padding: 4px 1rem !important;
  background-color: white !important;
}

.ratingVote-graph-container {
  text-align: center;
  padding: 1rem;
}
.ratingVote-graph {
  min-height: 250px;
}

@media (max-width: 600px) {
  .ratingVote-card {
    width: 100%;
    min-width: 100%;
  }
  .ratingVote-graph {
    height: 220px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .ratingVote-card {
    width: 100%;
    max-width: 100%;
  }
}

.pollVote {
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 0 1rem;
}

.vote__title {
  font-size: 1.25rem;
  color: rgba(117, 111, 134, 1);
  font-weight: bolder;
}

.pollVote .form {
  width: 100%;
}

.form__question {
  font-size: 1rem;
  color: #333;
  font-weight: bolder;
  word-wrap: break-word;
  margin-top: 0px;
}

.form__list {
  list-style-type: none;
  margin: 0.5rem 0;
  padding: 0;
}

.form__item {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  margin: 1rem 0rem;
  border-radius: 0.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  word-wrap: break-word;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: white;
}

.form__iconButton {
  border: 2px solid rgb(201, 201, 201) !important;
  height: 1.5rem;
  width: 1.5rem;
  margin: 1rem !important;
}

/* @media (max-width: 600px) {
  .form__iconButton {
    padding: 1rem !important;
  }
} */

.form__Opt {
  word-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 600px) {
  .form__Opt {
    font-size: 0.9rem;
    padding: 0 0 0 0.5rem;
  }
}

.form__btnGroup {
  text-align: center;
  padding: 0.4rem 0rem;
}

.form__btnGroup > button {
  /* font-size: 1.25rem; */
  width: 100%;
}

.form__checkIcon {
  color: rgba(244, 246, 250, 1);
}

.form__iconButton--active {
  border-color: rebeccapurple !important;
}
.form__checkIcon--active,
.form__item--active {
  color: rebeccapurple !important;
}
.pollVote .form__btn {
  background-color: rgba(39, 234, 150, 1) !important;
  color: white;
  border-radius: 25px;
  text-transform: capitalize;
}
.lockStatus {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.lockStatus__icon {
  color: #bdbdbd;
}
.lockStatus__msg {
  text-transform: capitalize;
  color: #bdbdbd;
}
.pollVote .form_btn--disabled {
  background-color: #bdbdbd !important;
}
.pollVote__no-poll {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.poll-image-body {
  height: 5.625rem;
  max-width: 5.375rem;
  align-self: flex-start;
}

.poll-image-body > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

@media (min-width: 768px) {
  .form__btnGroup > button {
    width: 7.625rem;
  }
}

.shimmerCard__container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.shimmerCard__body {
  height: 18rem;
  width: 100%;
  max-width: 40rem;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
}

.shimmerCard__center {
  display: flex;
  justify-content: center;
}

/* .content {
  box-sizing: border-box;
}
@media (max-width: 800px) {
  .content {
    width: 100vw;
  }
}
@media (max-width: 600px) {
  .content {
    width: 100vw;
  }
} */

#toggleBtn {
  position: fixed;
  top: 50%;
  left: 30px;
  z-index: 5000;
}

* {
  font-family: 'Montserrat' !important ;
}

:root {
  --secondary: #27ea96;
  --purple: rgba(150, 39, 234, 1);
  --grey-500: rgba(0, 0, 0, 0.5);
  --grey-300: rgba(0, 0, 0, 0.3);
  --yellow: rgba(241, 208, 0, 1);
  --lightBlue: rgba(235, 244, 248, 1);
  --black: rgba(44, 39, 56, 1);
  --green: #27ea96;
  --grey: rgba(117, 111, 134, 1);
  --offWhite: rgba(225, 225, 225, 1);
  --white: white;
}

/* ///////scrollbar:start/////// */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
/* ///////scrollbar:end/////// */

.MuiContainer-maxWidthLg {
  max-width: 1600px !important;
  margin: auto;
}
.app {
  display: flex;
  font-size: 0.875rem;
  background-color: #fafafa;
}

#zmmtg-root {
  display: none;
}

.sidebar {
  flex: 0.07 1;
  margin: 0 auto 0 0;
  padding: 0 10px;
  background-color: white;
  width: 90px;
  /* height: 100vh; */
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid lightgray;
  z-index: 990;
}

.right-sidebar {
  margin: 0 0 0 auto;
  padding: 1rem;
  background-color: white;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid lightgray;
  z-index: 990;
}
.sidebar-option {
  box-shadow: none;
  border: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin-top: 1rem;
  font-size: 12px;
  color: rgb(44, 43, 49);
  opacity: 0.7;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
}
.sidebar-option-active,
.sidebar-option:hover {
  background-color: transparent;
  color: rgb(150, 39, 234);
  cursor: pointer;
  opacity: 1;
  outline: none;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.161));
  overflow: visible;
}

.sidebar-option .MuiSvgIcon-root {
  margin: 3px auto;
}
.sidebar-option span {
  margin: 3px auto;
}

.app-right {
  width: calc(100% - 11.25rem);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex: 1 1;
}

.mobile-dashboard {
  display: flex;
  min-height: 15vh;
  width: 100%;
  border-bottom: 1px solid rgb(111, 49, 157);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 2000;
  display: none;
  background-color: rgb(111, 49, 157);
}
.mobile-dashboard-icon {
  background-color: rgb(111, 49, 157);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
}
.mobile-dashboard-menu {
  background-color: rgb(111, 49, 157);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-dashboard-content.MuiBottomNavigation-root {
  width: 100%;
  height: 15vh;
  background-color: rgb(111, 49, 157);
}
.mobile-dashboard-content.MuiBottomNavigation-root
  .MuiBottomNavigationAction-root.mobile-dashboard-button {
  color: white;
}
.MuiFormControl-root.dashboard-form {
  margin-right: 62vw;
}
.sidebar-option span {
  position: relative;
  top: 3px;
}
.MuiFormControl-root .MuiSelect-select {
  padding: 10px;
}
.event-status-text {
  color: white;
  border-radius: 10px;
  font-size: 15px;
  position: relative;
  bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
}

.user-content {
  width: 200px;
  margin-left: auto;
  display: flex;
  justify-content: space-evenly;
}
.user-icons.MuiSvgIcon-root {
  font-size: 30px;
  position: relative;
  top: 5px;
  cursor: pointer;
}
a {
  text-decoration: inherit;
}

.event-content-container {
  display: flex;
  flex-wrap: wrap;
}

.events-right-col {
  height: calc(70vh + 67px);
  overflow-y: auto;
  overflow-x: hidden;
}
.events p .MuiSvgIcon-root {
  color: rgb(111, 49, 157);
  cursor: pointer;
  position: relative;
  top: 5px;
  margin-left: 10px;
}
.events-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-search {
  display: flex;
  width: 20%;
  align-items: center;
}
.header-searchicon {
  padding: 5px;
  height: 22px !important;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: gray;
  border: 1px solid rgb(111, 49, 157);
  border-right: none;
}
.header-input {
  height: 12px;
  padding: 10px;
  border: none;
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
  border: 1px solid rgb(111, 49, 157);
  border-left: none;
  margin-right: 30px;
}
.new-event-button.MuiButton-root,
.new-event-button {
  background-color: rgb(111, 49, 157);
  color: white;
  border-radius: 10px;
  text-transform: none;
}
.new-event-button.MuiButton-root:hover,
.new-event-button:hover {
  background-color: rgb(140, 49, 157);
}

.events-header p {
  margin-right: 40vw;
}
.new-event-button {
  position: relative;
}
.all-btn {
  background-color: #f4f4f4 !important;
  border: 1px solid #333 !important;
  color: #333 !important;
}
.btn-left {
  top: 0 !important;
}

.events-header-icon {
  margin-left: 30px;
  position: relative;
  top: 10px;
  cursor: pointer;
}

.show-more {
  width: 100%;
  background-color: rgb(111, 49, 157) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.more-icon {
  margin-left: auto;
  width: 5%;
  display: flex;
  justify-content: center;
  padding-top: 28px;
  color: rgb(111, 49, 157);
}
.more-icon .MuiSvgIcon-root {
  cursor: pointer;
}

.MuiMenuItem-root .MuiSvgIcon-root {
  margin-right: 5px; /* updated-m */
}

.testing::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.testing::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.testing::-webkit-scrollbar-thumb {
  background: rgb(206, 210, 219, 0.8);
  border-radius: 10px;
}

/* Handle on hover */
.testing::-webkit-scrollbar-thumb:hover {
  background: rgb(150, 39, 234, 0.5);
}
.lock-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(111, 49, 157);
  position: relative;
  bottom: 22px;
  cursor: pointer;
}
.more-icons {
  margin-left: auto;

  display: flex;
  justify-content: space-evenly;
  padding-top: 28px;
}

.overview {
  height: 50%;
  width: 40%;
  border: 1px solid;
  margin: 10px 40px 40px 0;
}
.overview-header {
  background-color: rgb(111, 49, 157);
  color: white;
  height: 15%;
  display: flex;
}
.overview-header p {
  margin: 10px;
}
.overview-header p .MuiSvgIcon-root {
  position: relative;
  bottom: 5px;
}
.overview-body {
  text-align: center;
  margin: 20px;
}
.overview-body h1 {
  margin-bottom: 10px;
}
.overview-body p {
  margin-top: 10px;
  color: rgb(111, 49, 157);
}
.overviews {
  display: flex;
  justify-content: space-between;
}
.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 0 2rem;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #7e0af1, #b161e6);
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.message {
  font-size: 16px;
  width: 400px;
  cursor: pointer;
  position: relative;
  top: 40px;
  left: 70px;
  font-weight: bold;
}
.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1;
}
.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-new-poll {
  position: absolute;
  top: 25%; /*added-m*/
  height: 70%;
  width: 45%;
  border: 1px solid;
  margin-left: 15%;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: gray;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  border-radius: 10px;
}
.new-quiz-poll {
  position: absolute;
  height: 50%;
  width: 50%;
  /* margin: auto; */
  border: 1px solid gray;
  margin-top: 5%;
  margin-left: 15%;
  background-color: white;
  display: flex;
  color: gray;
  font-size: 14px;
  overflow: hidden;
  border-radius: 10px;
  z-index: 99999999999999999999999;
  bottom: 150px;
}
.popup-header {
  display: flex;
  font-size: 20px;
}
.popup-question input {
  width: 100%; /* update-m */
  margin-top: 10px;
  border: none;
  border-bottom: 2px solid gray;
  outline: none;
}
.custom-input {
  width: 400px;
  margin-top: 10px;
  border: none;
  border-bottom: 2px solid gray;
  outline: none;
}
.custom-input:focus {
  border-bottom: 2px solid green;
}
.popup-question input:focus {
  border-bottom: 2px solid green;
}
.stars {
  display: flex;
  justify-content: space-evenly;
}
.popup-checkboxes {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.popup-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.popup-footer > .btn-group {
  /* width: 100% !important; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.invite {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  border: 1px solid gray;
  height: -moz-fit-content;
  height: fit-content;
  left: 300px;
  z-index: 100000;
  margin: auto;
  width: 55%;
  top: 86px;
  height: calc(100vh - 96px);
  overflow-y: auto;
  overflow-x: hidden;
}
.invite-input {
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(117, 111, 134);
  height: 25px;
  width: 200px;
  margin: 0;
  width: 100%;
}
.invite-input:focus {
  box-shadow: 0 0 10px rgba(150, 39, 234, 0.29);
}

.modal-div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1% 2%;
  box-sizing: border-box;
}

.div-full {
  flex-direction: column;
  align-items: flex-start;
}

.modal-div > div {
  width: 48%;
}

.toggle-button {
  background-color: inherit;
  color: rgb(111, 49, 157);
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px;
  font-size: 14px;
  margin-bottom: 0;
}
.toggle-button-toggled {
  border-bottom: 4px solid rgb(111, 49, 157);
}
.table {
  /* min-height: 70vh; */
  min-width: 70vw;
  background-color: white;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
}
.table-header {
  display: flex;
  border: 1px solid black;
  font-size: 12px;
  color: gray;
  padding: 6px 0;
  text-align: center;
  font-weight: bold;
}
.table-content {
  font-size: 14px;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  /* box-shadow: 0 0 10px gray; */
}
.MuiIconButton-root.table-button .MuiSvgIcon-root {
  color: rgb(111, 49, 157);
}
.MuiIconButton-root.table-button:hover,
.MuiIconButton-root.MuiIconButton-root.table-button:active {
  background-color: white;
}

.active-polls {
  width: 100vw;
  height: 100vh;
  padding: 0 2rem;
  padding-right: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom right, #7e0af1, #b161e6);
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.active-poll {
  padding: 30px;
  font-weight: bold;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
}
.active-poll-active {
  background-color: white;
  color: rgb(151, 89, 199);
}
.active-poll span {
  background-color: gray;
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.active-polls-div {
  display: flex;
}

.active-polls-content {
  height: 80vh;
  width: 70vw;
  border-radius: 10px;
  background-color: rgb(111, 49, 157);
  overflow-y: auto;
}
.active-polls-footer {
  padding-left: 30px;
  padding-right: 30px;
  height: 80px;
  width: 60vw;
  background-color: black;
  border-radius: 50px;
  position: absolute;
  bottom: 2px;
  left: 300px;
  display: flex;
  justify-content: space-evenly;
}
.active-polls-footer-button {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-top: 15px;
  outline: none;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  cursor: pointer;
}
.results {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  color: white;
  background-color: rgb(165, 76, 233);
}
.results-card {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-left: 25vw;
}
.vote-bar {
  background-image: url('https://www.transparenttextures.com/patterns/pinstriped-suit.png');
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
.sharing-button {
  height: 50px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  color: rgb(111, 49, 157);
  font-family: 'Poppins', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
}
.sharing-button-live {
  height: 50px;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  background-color: white;
  border: 2px solid rgb(150, 39, 234);
  color: rgb(150, 39, 234);
  font-family: 'Poppins', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 10px;
}
.sharing-button:hover,
.sharing-button-live:hover {
  background-color: rgb(150, 39, 234);
  color: white;
}
.sharing-button span,
.sharing-button-live span {
  position: relative;
  bottom: 6px;
}
.agenda-questions p {
  margin-bottom: 10px;
}
.question {
  border: 1px solid black;
  margin-top: 6px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  color: rgb(92, 88, 88);
  font-size: 12px;
  display: flex;
}
.question p {
  margin: 5px;
}
.question:hover {
  background-color: #fff;
  /* box-shadow: 0 10px 10px rgba(111,49,157,0.7); */
  cursor: pointer;
}
.question-button.MuiButton-root {
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 0;
  padding-top: 5px;
  padding-right: 15px;
}
.user-dashboard {
  width: 100vw;
  height: 10vh;
  background-color: rgb(150, 39, 234);
  color: white;
  display: flex;
  justify-content: space-between;
}
.user-dashboard-button {
  background-color: rgb(150, 39, 234);
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  height: 100%;
  width: 80px;
}
.code-box {
  background-color: rgb(150, 39, 234);
  position: absolute;
  top: 40%;
  left: 35%;
  border-radius: 10px;
  width: 30vw;
  height: 20vh;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: bold;
}
.color-voting-div {
  transition-property: width;
  transition-duration: 2s;
}
.vote-box {
  position: absolute;
  top: 25%;
  left: 25%;
  border-radius: 10px;
  width: 50vw;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
  color: black;
}
.code-box-input {
  border: none;
  outline: none;
  width: 70%;
  font-size: 20px;
  color: rgb(150, 39, 234);
  font-weight: bold;
}
.code-box-input::placeholder {
  color: rgb(150, 39, 234);
  opacity: 0.75;
  font-weight: bold;
}
.code-box-button {
  background-color: rgb(150, 39, 234);
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  height: 100%;
  width: 80px;
  margin: auto 0;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 100%;
  padding: 5px;
}
.code-box-button:hover,
.code-box-button:active {
  background-color: rgb(165, 76, 233);
}

/* stage remote username style */
.stageRemoteUserName {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  color: #7b6666;
  font-weight: 600;
  padding: 0.375rem 1rem;
  margin: 0;
  z-index: 1;
  border: 1px solid #7b6666;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.userAtC1 {
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  margin: 0.375rem 0;
  border: 1px solid #a859e0;
}
.userAtC1 button {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: none;
}

.player {
  width: 100%;
  height: 100%;
  position: relative;
}
.player > div {
  background-color: transparent !important;
}
.streamUserName {
  position: absolute;
  background-color: green;
  color: white;
  /* color: #7b6666; */
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.125rem 0.5rem;
  margin: 0;
  letter-spacing: 0.05rem;
  z-index: 1;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
/* ///////////////media queries/////////////// */

@media (max-width: 1480px) {
  .events-header p {
    margin-right: 35vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1300px) {
  .MuiFormControl-root.dashboard-form {
    margin-right: 30vw;
  }
  .events-header p {
    margin-right: 30vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1200px) {
  .events-header p {
    margin-right: 25vw;
  }
  .active-polls-content {
    margin-right: 20px;
  }
  .active-polls-footer {
    width: 80vw;
    left: 5vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1100px) {
  .MuiFormControl-root.dashboard-form {
    margin-right: 20vw;
  }
  .events-header p {
    margin-right: 15vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 920px) {
  .MuiFormControl-root.dashboard-form {
    margin-right: 10vw;
  }
  .events-header p {
    margin-right: 5vw;
  }
  .event-id {
    display: none;
  }
  .login-content {
    margin-left: -80px;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 820px) {
  .results-image {
    display: none;
  }
  .results-card {
    width: 90vw;
    margin-left: 5vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 768px) {
  .sidebar,
  .dashboard,
  .right-sidebar {
    display: none;
  }
  .mobile-dashboard {
    display: flex;
    box-sizing: border-box;
  }
}

@media (max-width: 600px) {
  .header-search {
    display: none;
  }
  .events-header p {
    margin-right: 20px;
  }
  .custom-email-input {
    width: 50%;
    margin-left: -350px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 0;
    margin: 0;
  }
}

