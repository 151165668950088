.joinpage {
  height: 100vh;
}

.joinpage .navbar {
  box-sizing: border-box;
  height: 8rem;
}

.joinpage .navbar .navbar__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding-inline: 1.5rem;
  margin: 0;
}

.joinpage .navbar__item {
  flex-basis: 50%;
}

.joinpage .navbar__item.navbar__brand {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}
.joinpage .navbar__item:nth-child(2) {
  display: flex;
  justify-content: flex-end;
}
.joinpage .navbar__item:nth-child(2) > .navbar__social {
  text-align: center;
}

.joinpage .navbar__item:nth-child(2) > .navbar__social > p {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
}

.joinpage .navbar__iconBtn,
.joinpage .navbar__iconBtn:hover {
  background: #fff;
  margin-inline: 0.5rem;
}
.joinpage .body {
  height: calc(100vh - 8rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.joinpage .qrContainer {
  width: 40%;
  height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.joinpage .qrContainer__code {
  border: 2px solid #baba;
  height: 21.5rem;
  width: 23.75rem;
  border-radius: 2rem;
}

.joinpage .qrContainer__code > p {
  background: #ffffff;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 1.5rem;
  color: rgba(150, 39, 234, 1);
  font-weight: bold;
  height: 4rem;
}

.joinpage .qrContainer__code > div {
  height: calc(100% - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.joinpage .qrContainer__code > div > div {
  height: 80%;
  width: 60%;
}

.joinpage .my_qr {
  height: 100% !important;
  width: 100% !important;
}

.joinpage .qrContainer__desc {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  line-height: 0.4rem;
}
.joinpage .qrContainer__desc p{
  margin: 2rem 0;
}

@media (max-width: 1024px) {
  .joinpage .qrContainer {
    width: 100%;
  }
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
}

@media (max-width: 768px) {
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
}

@media (max-width: 600px) {
  .joinpage .qrContainer__code > p {
    font-size: 1rem;
  }
  .joinpage .qrContainer__code {
    width: 86%;
    height: 20.625rem;
  }
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
  .joinpage .navbar__item.navbar__brand {
    font-size: 1.2rem;
  }
  .joinpage .navbar__iconBtn {
    height: 1.4rem;
    width: 1.4rem;
  }
}

@media (max-width: 350px) {
  .joinpage .qrContainer__code > div > div {
    height: 80%;
    width: 60%;
  }
  .joinpage .qrContainer__desc {
    font-size: 1.4rem;
  }
}
