body {
  background: #f4f6fa;
}

/* stream home page */
.leftSideBar {
  background-color: white;
}

.sideBarHeader {
  height: 5rem;
  padding: 0.5rem 0.75rem;
}
.sideBarLinks {
  height: calc(100vh - 5rem);
  overflow: auto;
  padding: 0.5rem;
}
.sidebarOption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  margin: 0.5rem;
  text-align: center;
  font-size: 12px;
  color: #555;
  font-weight: bold;
}

.sidebarOption h2 {
  margin: 0;
}
.sidebarOption span {
  font-size: 9px;
}

.main {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: #f4f6fa;
}
.streamHeaderBox {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  gap: 0.5rem;
}
.streamHeaderBox h1,
.streamHeaderBox h2 {
  margin: 0;
  font-weight: 600;
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.streamHeaderBox h1::after {
  content: '';
  flex: 1;
  margin-left: 1rem;
  height: 2px;
  background-color: #aaaaaa;
}
.streamHeaderBox .icon {
  display: flex;
  font-size: 2.5rem;
  align-items: center;
}
@media (min-width: 1200px) {
  .streamHeaderBox,
  .streamContent {
    margin: 0 1rem;
  }
}
.gridParentBox {
  width: 100%;
  background: #f4f6fa;
}
.sponsorCardLeft {
  /* max-height: 27.063rem; */
  position: relative;
}
.sponsorCardLeft img {
  border-radius: 10px;
  /* height: 400px; */
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}
.sponsorCardLeft .addToCalendar {
  border-radius: 25px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.sponsorCardRight {
  /* max-height: 27.063rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: white;
  /* padding: 0.75rem; */
}
.sponsorLogo {
  display: flex;
  padding: 0.375rem;
  justify-content: center;
}
.host {
  text-align: center;
}
.host p {
  margin: 0;
  padding-top: 0.25rem;
}
.host h2 {
  margin: 0.25rem 0;
  font-weight: 600;
}
.host h4 {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
.shareOn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  padding: 0.75rem 0;
  font-weight: 600;
  color: #737182;
}
.social {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.agendaSpeaker img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

/* stream login page */
.loginParent {
  height: 100vh;
}
.leftCol {
  display: flex;
  margin: auto;
  width: 100%;
  height: 60%;
  background-color: #9627ea;
}


/* .loginContent h2,
p {
  color: white;
} */
.bannerParent {
  width: 100%;
  padding: 2rem 1rem;
}
.bannerParent img {
  width: 100%;
  border-radius: 1.625rem;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 25%);
  border: 0.1px solid #fff;
  max-width: 38.125rem;
  margin: auto;
}
/* speaker page */
.streamSpeakersParentBox {
  background-color: #f4f6fa;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
}
.speakerSession {
  border: 1px solid #a6a4ad;
  border-radius: 25px;
  background-color: #f2f6f9;
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 1rem;
}
.speakerSession h4,
.speakerSession h6 {
  margin: 0;
}
.speakerPhoto {
  width: 100%;
  object-fit: cover;
}
.speakerNameBox {
  background-color: #f4f6fa;
  font-size: 20px;
  border: 1px solid transparent;
  border-bottom: 1px solid #cac8c8;
  text-align: left;
  vertical-align: bottom;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  opacity: 0.8;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.designationBox {
  background-color: #f4f6fa;
  font-family: 'Montserrat';
  font-size: 0.938rem;
  align-items: flex-end;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  margin-bottom: 0px;
  border: 1px solid transparent;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  height: 1.563rem;
  text-align: left;
  vertical-align: bottom;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
}
.designationBox p {
  text-overflow: clip;
}

.SponsorImg {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.speakerGridBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
}
.speakerGrid {
  border-radius: 20px;
  border: 5px solid #fff;
  padding-left: 0rem !important;
  margin-bottom: 6rem;
  margin-top: 6rem;
}
.speakerGrid img {
  width: 100%;
  object-fit: cover;
}
.speakerSection {
  margin: 1rem;
}

.SpeakerImg {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  flex-wrap: wrap;
}

.spkImg {
  position: relative;
  justify-content: center;
  align-items: center;
}
.details {
  overflow-x: scroll;
  position: absolute;
  left: 12px;
  bottom: 10px;
  align-items: center;
  width: 200px;
  height: 60px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 0.15rem;
}
.details::-webkit-scrollbar {
  display: none;
}
.details h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding: 1rem;
  color: #000000;
}
.nameSocialLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.4rem;
  /* align-items: center; */
  /* padding: 0.2rem; */
}
.details p {
  margin: 0;
  text-align: center;
  font-size: 13px;
}
/* streamAgenda page */
.streamAgendaParentBox,
.streamAboutParentBox,
.streamDocumentParentBox {
  width: 100%;
  background: #f4f6fa;
  display: flex;
}
.streamVideoParentBox {
  display: flex;
}
.agendaScheduleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.agendaScheduleBtn .allBtn {
  background: #ffffff;
  border-radius: 50px;
  border: 4px solid #eaedfa;
  cursor: pointer;
}
.agendaScheduleBtn .agendaDateFilter {
  color: #ffffff !important;
  border: none !important;
  border-radius: 8px;
  font-size: 1rem;
}
.agendaScheduleContainer {
  background: #ffffff;
  padding: 1.25rem;
  border-radius: 0.5rem;
}
.agendaScheduleDate {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 700;
}
.agendaScheduleContent {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 2rem;
}
.time {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgb(91, 89, 89);
  font-size: 16px;
  font-weight: bold;
  padding: 1rem;
  border-radius: 8px;
  height: 3rem;
  /* background-color: #ffffff !important; */
}
.time p {
  white-space: nowrap;
}
.topic {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
.agendaSpeakerDetails {
  display: flex;
  margin: 1.4rem 0;
  align-items: center;
  gap: 1rem;
}
.speakerName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.speakerDetail h1 {
  cursor: pointer;
  font-size: 17px;
  color: #393938;
  font-weight: bold;
  margin-bottom: 2px;
  font-family: 'Montserrat', sans-serif;
}
.speakerDetail .speakerName {
  color: #545454;
  font-size: 0.938rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-top: -2.4rem;
}
.speakerDetail .speakerPosition {
  color: #808080;
  font-size: 0.938rem;
  margin-top: 0.625rem;
  white-space: wrap;
}

/* .joinSession {
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  min-width: 215px;
  padding: 12px 15px;
  margin-bottom: 10px;
} */
.sessionLocationParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;
  /* position: relative; */
}
.sessionLocation {
  background: #ffffff !important;
  border-radius: 10px;
}
.speakerIconsParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 1rem; */
  /* position: absolute;
  right: 0; */
}
.datePicker {
  gap: 0.5rem;
  padding: 0.4rem;
  color: #ffffff;
  width: 6rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

/* stream sponsors page */

.sponsorBannerBox {
  /* width: 30%; */
  height: auto;
  border-radius: 8px;
  position: relative;
  object-fit: cover;
}

.sponsorLogoBox {
  position: absolute;
  left: 4px;
  top: 3px;
  margin: -0.8% -0.8%;
  padding: 2px;
  border-radius: 10px;
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}
.sponsorLogoBox img {
  padding: 5%;
  object-fit: cover;
  border-radius: 5px;
  width: 30px;
}
.sponsorDetailBox {
  display: flex;
  flex-direction: column;
  background: #fff;
  opacity: 0.9;
  padding: 0px 15px;
  margin: 0px 4px;
  width: calc(100% + 6px);
  margin-bottom: -8px;
  margin-left: -3px;
}
.sponsorCompanyName {
  text-overflow: hidden;
  overflow: hidden;
  padding: 1px 0px;
  padding-bottom: 4px;
  width: 470px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 1.563rem;
  color: #2c2738;
  text-transform: uppercase !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

/* Expanded sponsors page */
.BannerParentBox {
  /* width: 100%;
  margin: 2rem;
  display: flex;
  justify-content: flex-end;
  border-radius: 0.625rem;
  background-repeat: no-repeat;
  background-size: contain, cover; */
  background-size: cover;
  display: grid;
  grid-template-rows: 25em auto;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;
  position: relative;
  margin-top: 1rem;
}

.SponsorCompanyLogoParent {
  background-size: cover;
  position: absolute;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 35%;
  box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
  /* height: 18.75rem; */
  height: -webkit-fill-available;
  backdrop-filter: blur(14px);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
}
/* .SponsorCompanyLogoParent img {
  height: 40%;
} */
.SponsorCompanyLogoParent h1 {
  font-size: 0.625rem;
}
.ExhibitorBannerParentBox {
  border-radius: 10px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 100%;

  /* position: relative; */
}
.exiStreamVideo {
  /* position: absolute; */
  width: 100%;
  height: 100%;
}
.ExhibitorCompanyLogoParent img {
  width: 200px;
}
/* .exhibitorDetailMain{
  padding: 0 1rem;
} */
/* .exhibitorDetailMain p{
  line-height: 30px;
} */
.meetingContactDocsParent {
  display: grid;
  margin: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}
.meetingContactDocsParent .meet {
  border-radius: 10px;
  border: 5px solid #fff;
  text-align: left;
  font-size: 30px;
  width: 100%;
  background-color: white;
  overflow: hidden;
}
.meetingContactDocsParent .meet #bookNowBtn {
  text-align: center;
}

.meet h4 {
  font-size: 1.8rem;
  margin: 10px 0 0 0;
  font-weight: 600;
  text-align: center;
  flood-color: #9627ea;
  color: #9627ea;
}
.meet p {
  margin-left: 10px;
  margin-right: 5px;
  font-size: 0.8rem;
  color: #756f86;
}
.bookSlot {
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
}
.contactDetl p {
  margin: 10px 10px 0 5px;
  font-size: 0.8rem;
  color: #756f86;
  display: flex;
  align-items: center;
  gap: 2px;
}
.meet .bookMeetingDate {
  display: grid;
  margin-left: 10px;
  margin-right: 10px;
  grid-template-columns: repeat(5, 1.5em);
  grid-template-rows: 3.5rem;
  cursor: pointer;
  grid-gap: 10px;
}
.meet .bookMeetingDate div {
  border: 1px solid #756f86;
  font-size: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #756f86;
  border-radius: 5px;
}
.meet .bookMeetingDate div:hover {
  color: white;
  background-color: #9627ea;
  border: none;
}

.exhibitorCompany {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 1px 0px 4px 1px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #2c2738;
  text-transform: uppercase !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

/* ****************Stream Video.js************ */
.streamVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.streamVideoContainer p {
  color: white;
  margin: 0;
}
.docDetails,
.vidDetails {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin: -7px 0 0 0;
}
.vidDetails p {
  width: 240px;
  text-overflow: ellipsis;
}
.videoTitle,
.docTitle {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
}
.videoTitle h1,
.docTitle h1 {
  font-size: 20px;
  margin: 0;
}

/* **********Styles for Survey Questions********** */
.surveyMain {
  padding: 2rem;
  margin: 0 2rem 2rem;
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 7px 10px 5px rgba(0, 0, 0, 0.25);
}
.surveyBtn {
  display: flex;
  justify-content: center;
  margin: 0 0 2rem;
}
.menuIcon {
  display: flex;
  color: #000;
}
/* streamLeaderboard  */
.leaderBoardGrid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  /* margin-top: 1rem; */
}
.leaderBoardGrid .leaderboardCard {
  border-radius: 25px;
  border: 6px solid #fff;
  text-align: center;
  font-size: 30px;
  background-size: 100% 100%;
  position: relative;
  box-shadow: 5px 10px 8px #ddd;
  margin: 1rem;
}
.leaderboardCard .medal {
  position: absolute;
  right: 0rem;
  top: 0rem;
}
.leaderboardCard .leaderName {
  background-color: #fff;
  padding: 0.5rem;
  width: max-content;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  text-emphasis: bold;
  font-size: 23px;
  float: center;
  font-weight: bolder;
  color: #8f8686;
  border: 1px solid transparent;
  border-radius: 100px;
  vertical-align: bottom;
  transform: translateX(-50%);
  box-shadow: 5px 10px 8px #ddd;
}
.leaderboardResults .resultElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  height: 90px;
  color: #8f8686;
  font-weight: bold;
  font-size: 25px;
  background-color: #fff;
  border: 5px solid white;
  margin-left: 10px;
}
.leaderboardResults .resultElement img {
  max-width: 100% !important;
  height: 100%;
  object-fit: contain;
}
.textMessage{
  text-align: center;
  vertical-align: middle;
}
@media screen and (min-width: 600px) {
  .loginParent {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .leftCol {
    flex-basis: 50%;
    height:100%;
  }
  .loginContent {
    width: 80% !important;
  }
  .bannerParent {
    flex-basis: 50%;
  }
  .gridParentBox {
    width: calc(100% - 90px);
    margin-left: auto;
    display: flex;
  }
  .speakerGridBox {
    width: calc(100% - 90px);
  }
  .agendaScheduleBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .agendaScheduleContent {
    display: flex;
    justify-content: flex-start;
    /* flex-wrap: wrap; */
    gap: 2rem;
  }
  .sponsorBannerBox {
    /* width: 31.25rem; */
    height: 15.625rem;
  }
  .img {
    width: 48%;
   
  }
  .SponsorCompanyLogoParent {
    width: 25%;
  }
  .BannerParentBox {
    margin: 2rem;
  }
  .meetingContactDocsParent .meet {
    width: 10em;
  }
  .meetingContactDocsParent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 3rem;
  }
  .menuIcon {
    display: none;
  }
  /* .main {
    width: calc(100% - 12.3rem);
  } */
}
@media (min-width: 758px) and (max-width: 1024px) {
  .meetingContactDocsParent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0.5rem;
    gap: 1rem;
  }
  .menuIcon {
    display: none;
  }
}
