.newsignup {
  background-color: rgba(245, 245, 245, 1);
  padding: 1rem 1rem 0 1rem;
  min-height: 100vh;
}

.newsignup .newsignup__container {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.newsignup .newsignup__header {
  display: block;
}

.newsignup .newSignup__list {
  display: flex;
  list-style-type: none;
  align-items: center;
  /* padding: 0.5rem 2rem; */
  margin: 0;
}

.newsignup .newSignup__listItem {
  flex-basis: 50%;
  text-align: right;
}

.newsignup .brandName {
  font-size: 1.5rem;
  color: var(--primary);
  font-weight: bold;
}

.newsignup .newSignup__listItem > .newSignup__btn {
  border-radius: 2rem;
}

.newsignup .leftCol,
.newsignup .rightCol {
  flex-basis: 46%;
}

.newsignup .lefCol__msgContainer {
  display: flex;
  justify-content: flex-end;
}

.newsignup .leftCol__title > h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.newsignup .leftCol__title > p {
  font-weight: bold;
  font-size: 1.5rem;
}

.newsignup .leftCol__imgContainer {
  max-height: 23rem;
  padding-top: 2rem;
  overflow-y: clip;
}

.newsignup .leftCol__imgWrapper {
  position: relative;
  height: 26rem;
  width: 26rem;
  padding: 3rem;
  border-radius: 50%;
  margin-left: -5rem;
  box-shadow: inset 0 0 55px 20px white;
}

.newsignup .img__shadow {
  position: absolute;
  top: 35%;
  left: 35%;
  height: 5rem;
  width: 7rem;
  border-radius: 50%;
  box-shadow: 1px 1px 7rem 40px #9627ea;
}

.newsignup .leftCol__imgWrapper > img {
  /* height: 100%;
  width: 100%;
  margin-top: -4rem;
  margin-left: 2rem; */

  position: absolute;
  top: -2rem;
  left: 5rem;
  height: 75%;
  width: 75%;
}

/* .newsignup .rightCol {
  padding: 0rem 2rem 2rem 2rem;
} */

.newsignup .form__wrapper {
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.newsignup .form__wrapper .form__group {
  flex-basis: 48%;
  display: flex;
  flex-direction: column;
}

.newsignup .form__group {
  width: 98%;
  display: flex;
  flex-direction: column;
}

.newsignup .form__group > label {
  padding-block: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(117, 111, 134, 1);
}
.newsignup .form__select .MuiInputBase-input {
  height: 2.4rem;
}
.newsignup .buttonGroup {
  width: 98%;
  text-align: center;
  margin-top: 2rem;
}

.newsignup .buttonGroup .buttonGroup__button {
  background: rgba(150, 39, 234, 1);
  color: #fff;
  border-radius: 2rem;
  height: 3rem;
  width: 8rem;
}

.newsignup .googleMsg {
  font-size: 1rem;
  color: rgba(117, 111, 134, 1);
  text-align: center;
  position: relative;
}

.newsignup .googleMsg::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 10rem;
  background-color: rgba(112, 112, 112, 0.502);
  top: 50%;
  left: 0.5rem;
}

.newsignup .googleMsg::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 10rem;
  background-color: rgba(136, 9, 9, 0.502);
  top: 50%;
  right: 0.25rem;
}

.newsignup .googleicn {
  height: 3rem;
  width: 4.2rem;
  border: 1px solid rgba(112, 112, 112, 0.502);
  margin: auto;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;
}

.newsignup .googleicn > img {
  height: 100%;
  width: 100%;
}

/* new update */

.newsignup .MuiFilledInput-input {
  padding: 18px 12px 16px !important;
}

.newsignup .MuiFilledInput-root,
.newsignup .MuiFilledInput-root:hover {
  background-color: #8ecfe21f !important;
  border-radius: 0.4rem;
}

.newsignup .MuiFilledInput-underline:before {
  border-bottom: unset !important;
}
.newsignup .MuiFilledInput-underline:hover:before {
  border-bottom: unset !important;
}

@media (min-width: 1280px) {
  .newsignup .rightCol {
    padding: 0rem 4rem 2rem 2rem;
  }
  .newsignup .newsignup__container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 1279px) and (min-width: 960px) {
  .newsignup .rightCol {
    padding: 0rem 2rem 2rem 2rem;
  }
  .newsignup .newsignup__container {
    margin-top: 2rem;
  }

  .newsignup .googleMsg::before,
  .newsignup .googleMsg::after {
    width: 7rem;
  }
}

@media (max-width: 768px) {
  .newsignup .leftCol {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .newsignup .newsignup__container {
    flex-direction: column;
  }

  .newsignup .leftCol__title > h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .newsignup .leftCol__imgContainer {
    padding-top: 0;
  }
  .newsignup .leftCol__imgWrapper {
    height: 18rem;
    width: 18rem;
  }

  /* .newsignup .leftCol__imgWrapper > img {
    margin-top: -2rem;
  } */
  .newsignup .img__shadow {
    position: absolute;
    top: 45%;
    left: 35%;
    height: 3rem;
    width: 6rem;
    border-radius: 50%;
    box-shadow: 1px 1px 4rem 20px #9627ea;
  }
  .newsignup .leftCol__imgWrapper > img {
    top: 0;
    left: 4rem;
  }
}

.newsignup .signUp--hidesm,
.newsignup .joinEvent--sm {
  display: none;
}

@media (max-width: 600px) {
  .newsignup .leftCol__title > h1 {
    font-size: 2rem;
    line-height: 4rem;
  }

  .newsignup .hide--sm {
    display: none;
  }

  .newsignup .newSignup__list {
    justify-content: center;
  }
  .newsignup .leftCol {
    display: none;
  }
  .newsignup .rightCol {
    flex-basis: 100%;
    padding-inline: 0.5rem;
  }
  .newsignup .newSignup__listItem {
    text-align: center;
  }
  .newsignup .signUp--hidesm {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  .newsignup .joinEvent--sm {
    display: block;
    text-align: center;
    margin-block: 1rem;
  }

  .newsignup .signUp--hidesm,
  .newsignup .joinEvent--sm {
    display: block;
  }

  .newsignup .joinEvent--sm .btnGroup__btn {
    border-radius: 2rem;
  }
  .newsignup .googleMsg::before,
  .newsignup .googleMsg::after {
    width: 5rem;
  }
}
