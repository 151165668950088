.mobileHeader {
  padding: 0.6rem 0.875rem 0.6rem 0.2rem;
  margin: 0rem;
  background-color: white;
  display: none;
  position: sticky;
  top: 0rem;
  z-index: 3;
}

.mobileHeader .mobileHeader__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileHeader .mobileHeader__title .title {
  color: #000;
  margin: 0rem;
  text-transform: capitalize;
  font-size: 1.3rem;
}

.mobileHeader .mobileHeader__avatar .profileImg {
  height: 100%;
  width: 100%;
}

/* //// media query //////// */
@media (max-width: 768px) {
  .mobileHeader {
    display: block;
  }
}

@media (max-width: 350px) {
  .mobileHeader .mobileHeader__title .title {
    font-size: 1.2rem;
  }
}
