@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
* {
  font-family: 'Montserrat' !important ;
}

:root {
  --secondary: #27ea96;
  --purple: rgba(150, 39, 234, 1);
  --grey-500: rgba(0, 0, 0, 0.5);
  --grey-300: rgba(0, 0, 0, 0.3);
  --yellow: rgba(241, 208, 0, 1);
  --lightBlue: rgba(235, 244, 248, 1);
  --black: rgba(44, 39, 56, 1);
  --green: #27ea96;
  --grey: rgba(117, 111, 134, 1);
  --offWhite: rgba(225, 225, 225, 1);
  --white: white;
}

/* ///////scrollbar:start/////// */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
/* ///////scrollbar:end/////// */

.MuiContainer-maxWidthLg {
  max-width: 1600px !important;
  margin: auto;
}
.app {
  display: flex;
  font-size: 0.875rem;
  background-color: #fafafa;
}

#zmmtg-root {
  display: none;
}

.sidebar {
  flex: 0.07;
  margin: 0 auto 0 0;
  padding: 0 10px;
  background-color: white;
  width: 90px;
  /* height: 100vh; */
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid lightgray;
  z-index: 990;
}

.right-sidebar {
  margin: 0 0 0 auto;
  padding: 1rem;
  background-color: white;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid lightgray;
  z-index: 990;
}
.sidebar-option {
  box-shadow: none;
  border: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin-top: 1rem;
  font-size: 12px;
  color: rgb(44, 43, 49);
  opacity: 0.7;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
}
.sidebar-option-active,
.sidebar-option:hover {
  background-color: transparent;
  color: rgb(150, 39, 234);
  cursor: pointer;
  opacity: 1;
  outline: none;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.161));
  overflow: visible;
}

.sidebar-option .MuiSvgIcon-root {
  margin: 3px auto;
}
.sidebar-option span {
  margin: 3px auto;
}

.app-right {
  width: calc(100% - 11.25rem);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex: 1;
}

.mobile-dashboard {
  display: flex;
  min-height: 15vh;
  width: 100%;
  border-bottom: 1px solid rgb(111, 49, 157);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 2000;
  display: none;
  background-color: rgb(111, 49, 157);
}
.mobile-dashboard-icon {
  background-color: rgb(111, 49, 157);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
}
.mobile-dashboard-menu {
  background-color: rgb(111, 49, 157);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-dashboard-content.MuiBottomNavigation-root {
  width: 100%;
  height: 15vh;
  background-color: rgb(111, 49, 157);
}
.mobile-dashboard-content.MuiBottomNavigation-root
  .MuiBottomNavigationAction-root.mobile-dashboard-button {
  color: white;
}
.MuiFormControl-root.dashboard-form {
  margin-right: 62vw;
}
.sidebar-option span {
  position: relative;
  top: 3px;
}
.MuiFormControl-root .MuiSelect-select {
  padding: 10px;
}
.event-status-text {
  color: white;
  border-radius: 10px;
  font-size: 15px;
  position: relative;
  bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
}

.user-content {
  width: 200px;
  margin-left: auto;
  display: flex;
  justify-content: space-evenly;
}
.user-icons.MuiSvgIcon-root {
  font-size: 30px;
  position: relative;
  top: 5px;
  cursor: pointer;
}
a {
  text-decoration: inherit;
}

.event-content-container {
  display: flex;
  flex-wrap: wrap;
}

.events-right-col {
  height: calc(70vh + 67px);
  overflow-y: auto;
  overflow-x: hidden;
}
.events p .MuiSvgIcon-root {
  color: rgb(111, 49, 157);
  cursor: pointer;
  position: relative;
  top: 5px;
  margin-left: 10px;
}
.events-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-search {
  display: flex;
  width: 20%;
  align-items: center;
}
.header-searchicon {
  padding: 5px;
  height: 22px !important;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: gray;
  border: 1px solid rgb(111, 49, 157);
  border-right: none;
}
.header-input {
  height: 12px;
  padding: 10px;
  border: none;
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
  border: 1px solid rgb(111, 49, 157);
  border-left: none;
  margin-right: 30px;
}
.new-event-button.MuiButton-root,
.new-event-button {
  background-color: rgb(111, 49, 157);
  color: white;
  border-radius: 10px;
  text-transform: none;
}
.new-event-button.MuiButton-root:hover,
.new-event-button:hover {
  background-color: rgb(140, 49, 157);
}

.events-header p {
  margin-right: 40vw;
}
.new-event-button {
  position: relative;
}
.all-btn {
  background-color: #f4f4f4 !important;
  border: 1px solid #333 !important;
  color: #333 !important;
}
.btn-left {
  top: 0 !important;
}

.events-header-icon {
  margin-left: 30px;
  position: relative;
  top: 10px;
  cursor: pointer;
}

.show-more {
  width: 100%;
  background-color: rgb(111, 49, 157) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.more-icon {
  margin-left: auto;
  width: 5%;
  display: flex;
  justify-content: center;
  padding-top: 28px;
  color: rgb(111, 49, 157);
}
.more-icon .MuiSvgIcon-root {
  cursor: pointer;
}

.MuiMenuItem-root .MuiSvgIcon-root {
  margin-right: 5px; /* updated-m */
}

.testing::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.testing::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.testing::-webkit-scrollbar-thumb {
  background: rgb(206, 210, 219, 0.8);
  border-radius: 10px;
}

/* Handle on hover */
.testing::-webkit-scrollbar-thumb:hover {
  background: rgb(150, 39, 234, 0.5);
}
.lock-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(111, 49, 157);
  position: relative;
  bottom: 22px;
  cursor: pointer;
}
.more-icons {
  margin-left: auto;

  display: flex;
  justify-content: space-evenly;
  padding-top: 28px;
}

.overview {
  height: 50%;
  width: 40%;
  border: 1px solid;
  margin: 10px 40px 40px 0;
}
.overview-header {
  background-color: rgb(111, 49, 157);
  color: white;
  height: 15%;
  display: flex;
}
.overview-header p {
  margin: 10px;
}
.overview-header p .MuiSvgIcon-root {
  position: relative;
  bottom: 5px;
}
.overview-body {
  text-align: center;
  margin: 20px;
}
.overview-body h1 {
  margin-bottom: 10px;
}
.overview-body p {
  margin-top: 10px;
  color: rgb(111, 49, 157);
}
.overviews {
  display: flex;
  justify-content: space-between;
}
.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 0 2rem;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #7e0af1, #b161e6);
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.message {
  font-size: 16px;
  width: 400px;
  cursor: pointer;
  position: relative;
  top: 40px;
  left: 70px;
  font-weight: bold;
}
.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1;
}
.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-new-poll {
  position: absolute;
  top: 25%; /*added-m*/
  height: 70%;
  width: 45%;
  border: 1px solid;
  margin-left: 15%;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: gray;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  border-radius: 10px;
}
.new-quiz-poll {
  position: absolute;
  height: 50%;
  width: 50%;
  /* margin: auto; */
  border: 1px solid gray;
  margin-top: 5%;
  margin-left: 15%;
  background-color: white;
  display: flex;
  color: gray;
  font-size: 14px;
  overflow: hidden;
  border-radius: 10px;
  z-index: 99999999999999999999999;
  bottom: 150px;
}
.popup-header {
  display: flex;
  font-size: 20px;
}
.popup-question input {
  width: 100%; /* update-m */
  margin-top: 10px;
  border: none;
  border-bottom: 2px solid gray;
  outline: none;
}
.custom-input {
  width: 400px;
  margin-top: 10px;
  border: none;
  border-bottom: 2px solid gray;
  outline: none;
}
.custom-input:focus {
  border-bottom: 2px solid green;
}
.popup-question input:focus {
  border-bottom: 2px solid green;
}
.stars {
  display: flex;
  justify-content: space-evenly;
}
.popup-checkboxes {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.popup-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.popup-footer > .btn-group {
  /* width: 100% !important; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.invite {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  border: 1px solid gray;
  height: fit-content;
  left: 300px;
  z-index: 100000;
  margin: auto;
  width: 55%;
  top: 86px;
  height: calc(100vh - 96px);
  overflow-y: auto;
  overflow-x: hidden;
}
.invite-input {
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(117, 111, 134);
  height: 25px;
  width: 200px;
  margin: 0;
  width: 100%;
}
.invite-input:focus {
  box-shadow: 0 0 10px rgba(150, 39, 234, 0.29);
}

.modal-div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1% 2%;
  box-sizing: border-box;
}

.div-full {
  flex-direction: column;
  align-items: flex-start;
}

.modal-div > div {
  width: 48%;
}

.toggle-button {
  background-color: inherit;
  color: rgb(111, 49, 157);
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px;
  font-size: 14px;
  margin-bottom: 0;
}
.toggle-button-toggled {
  border-bottom: 4px solid rgb(111, 49, 157);
}
.table {
  /* min-height: 70vh; */
  min-width: 70vw;
  background-color: white;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
}
.table-header {
  display: flex;
  border: 1px solid black;
  font-size: 12px;
  color: gray;
  padding: 6px 0;
  text-align: center;
  font-weight: bold;
}
.table-content {
  font-size: 14px;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  /* box-shadow: 0 0 10px gray; */
}
.MuiIconButton-root.table-button .MuiSvgIcon-root {
  color: rgb(111, 49, 157);
}
.MuiIconButton-root.table-button:hover,
.MuiIconButton-root.MuiIconButton-root.table-button:active {
  background-color: white;
}

.active-polls {
  width: 100vw;
  height: 100vh;
  padding: 0 2rem;
  padding-right: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom right, #7e0af1, #b161e6);
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.active-poll {
  padding: 30px;
  font-weight: bold;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
}
.active-poll-active {
  background-color: white;
  color: rgb(151, 89, 199);
}
.active-poll span {
  background-color: gray;
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.active-polls-div {
  display: flex;
}

.active-polls-content {
  height: 80vh;
  width: 70vw;
  border-radius: 10px;
  background-color: rgb(111, 49, 157);
  overflow-y: auto;
}
.active-polls-footer {
  padding-left: 30px;
  padding-right: 30px;
  height: 80px;
  width: 60vw;
  background-color: black;
  border-radius: 50px;
  position: absolute;
  bottom: 2px;
  left: 300px;
  display: flex;
  justify-content: space-evenly;
}
.active-polls-footer-button {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-top: 15px;
  outline: none;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  cursor: pointer;
}
.results {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  color: white;
  background-color: rgb(165, 76, 233);
}
.results-card {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-left: 25vw;
}
.vote-bar {
  background-image: url('https://www.transparenttextures.com/patterns/pinstriped-suit.png');
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
.sharing-button {
  height: 50px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  color: rgb(111, 49, 157);
  font-family: 'Poppins', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
}
.sharing-button-live {
  height: 50px;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  background-color: white;
  border: 2px solid rgb(150, 39, 234);
  color: rgb(150, 39, 234);
  font-family: 'Poppins', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 10px;
}
.sharing-button:hover,
.sharing-button-live:hover {
  background-color: rgb(150, 39, 234);
  color: white;
}
.sharing-button span,
.sharing-button-live span {
  position: relative;
  bottom: 6px;
}
.agenda-questions p {
  margin-bottom: 10px;
}
.question {
  border: 1px solid black;
  margin-top: 6px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  color: rgb(92, 88, 88);
  font-size: 12px;
  display: flex;
}
.question p {
  margin: 5px;
}
.question:hover {
  background-color: #fff;
  /* box-shadow: 0 10px 10px rgba(111,49,157,0.7); */
  cursor: pointer;
}
.question-button.MuiButton-root {
  height: fit-content;
  padding-left: 0;
  padding-top: 5px;
  padding-right: 15px;
}
.user-dashboard {
  width: 100vw;
  height: 10vh;
  background-color: rgb(150, 39, 234);
  color: white;
  display: flex;
  justify-content: space-between;
}
.user-dashboard-button {
  background-color: rgb(150, 39, 234);
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  height: 100%;
  width: 80px;
}
.code-box {
  background-color: rgb(150, 39, 234);
  position: absolute;
  top: 40%;
  left: 35%;
  border-radius: 10px;
  width: 30vw;
  height: 20vh;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: bold;
}
.color-voting-div {
  transition-property: width;
  transition-duration: 2s;
}
.vote-box {
  position: absolute;
  top: 25%;
  left: 25%;
  border-radius: 10px;
  width: 50vw;
  height: fit-content;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
  color: black;
}
.code-box-input {
  border: none;
  outline: none;
  width: 70%;
  font-size: 20px;
  color: rgb(150, 39, 234);
  font-weight: bold;
}
.code-box-input::placeholder {
  color: rgb(150, 39, 234);
  opacity: 0.75;
  font-weight: bold;
}
.code-box-button {
  background-color: rgb(150, 39, 234);
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  height: 100%;
  width: 80px;
  margin: auto 0;
  width: fit-content;
  height: fit-content;
  border-radius: 100%;
  padding: 5px;
}
.code-box-button:hover,
.code-box-button:active {
  background-color: rgb(165, 76, 233);
}

/* stage remote username style */
.stageRemoteUserName {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  color: #7b6666;
  font-weight: 600;
  padding: 0.375rem 1rem;
  margin: 0;
  z-index: 1;
  border: 1px solid #7b6666;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.userAtC1 {
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  margin: 0.375rem 0;
  border: 1px solid #a859e0;
}
.userAtC1 button {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: none;
}

.player {
  width: 100%;
  height: 100%;
  position: relative;
}
.player > div {
  background-color: transparent !important;
}
.streamUserName {
  position: absolute;
  background-color: green;
  color: white;
  /* color: #7b6666; */
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.125rem 0.5rem;
  margin: 0;
  letter-spacing: 0.05rem;
  z-index: 1;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
/* ///////////////media queries/////////////// */

@media (max-width: 1480px) {
  .events-header p {
    margin-right: 35vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1300px) {
  .MuiFormControl-root.dashboard-form {
    margin-right: 30vw;
  }
  .events-header p {
    margin-right: 30vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1200px) {
  .events-header p {
    margin-right: 25vw;
  }
  .active-polls-content {
    margin-right: 20px;
  }
  .active-polls-footer {
    width: 80vw;
    left: 5vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1100px) {
  .MuiFormControl-root.dashboard-form {
    margin-right: 20vw;
  }
  .events-header p {
    margin-right: 15vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 920px) {
  .MuiFormControl-root.dashboard-form {
    margin-right: 10vw;
  }
  .events-header p {
    margin-right: 5vw;
  }
  .event-id {
    display: none;
  }
  .login-content {
    margin-left: -80px;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 820px) {
  .results-image {
    display: none;
  }
  .results-card {
    width: 90vw;
    margin-left: 5vw;
  }
  .event-content-container {
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 768px) {
  .sidebar,
  .dashboard,
  .right-sidebar {
    display: none;
  }
  .mobile-dashboard {
    display: flex;
    box-sizing: border-box;
  }
}

@media (max-width: 600px) {
  .header-search {
    display: none;
  }
  .events-header p {
    margin-right: 20px;
  }
  .custom-email-input {
    width: 50%;
    margin-left: -350px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 0;
    margin: 0;
  }
}
