@media (max-width: 768px) {
  .eventTicketInfo {
    width: 100vw;
    padding: 20px 15px;
  }

  .flexContainer {
    flex-direction: column;
    align-items: center;
  }

  .dateContainer,
  .feeContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}

.regAttendeeMain {
  padding: 0;
  margin: 0;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  width: 100%;
  background: #ffffff;
  position: sticky;
  top: 0;
}
.logo {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  align-items: center;
  gap: 2rem;
  /* margin: 1rem; */
}
.header .navItem {
  text-decoration: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.9);
}
.menuIcon {
  display: none;
}
.drwerHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 200px;
}
.drwerHeader a {
  color: #000000;
}
.drawerIcon {
  /* border-bottom: 1px solid grey; */
  border-bottom-width: 100%;
  box-shadow: 7px 0px 10px rgba(40, 40, 40, 0.25);
  padding: 0.5rem;
}
.mainHeader {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 1rem 0;
  justify-content: space-between;
}
.leftHeader {
  flex-basis: 50%;
  padding-left: 1.5rem;
}
.leftHeader h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0.02em;

  color: #000000;
}
.leftHeader p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;

  color: #666666;
}
.rightImg {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
}
/* ************Agenda Section****************** */
.agenda {
  margin-top: 2rem;
}
.agendaTimeline {
  max-width: 700px;
  margin: 0 auto;
}
.agendatimelineBody h4 {
  margin: 0 0 0 22%;
  border-radius: 4px;
  padding: 0.5rem;
  color: #ffffff;
}

.timeNlocation {
  flex-basis: 30%;
  padding-top: 1rem;
}
.speakerDetail h1 {
  cursor: pointer;
  font-size: 17px;
  color: #393938;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}
.speakerDetail p {
  font-size: 14px;
  color: #393938;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}
.agendaSpeaker {
  display: flex;
  margin: 2.5rem 0;
  align-items: center;
  gap: 1rem;
}
.agendaSpeaker img {
  border-radius: 50%;
}
/* .timelineItem h6{
  position: relative;
} */
.timelineItem {
  display: flex;
}
.time {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(91, 89, 89);
  font-size: 16px;
  font-weight: bold;
}
.time p {
  word-wrap: break-word;
  margin: 0;
}
.location {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  color: #777;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.agendaTitle {
  display: flex;
  align-items: center;
}
.agendaTitle h2 {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  box-sizing: border-box;
  padding: 1rem;
  cursor: pointer;
}
.content {
  padding-left: 40px;
  padding-bottom: 40px;
  flex-basis: 70%;
  border-left: 3px solid rgb(91, 89, 89);
  padding-top: 1rem;
}
.agdModaltime {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgb(91, 89, 89);
  font-size: 13px;
  font-weight: bold;
  padding: 1rem;
  border-radius: 8px;
  height: 3rem;
  /* background-color: #ffffff !important; */
}
.agdModaltime p {
  white-space: nowrap;
}
/* ***********Speaker Section*************** */
.speakerSection {
  margin: 2rem 5rem;
}
.speakerSection h1 {
  text-align: center;
  margin-bottom: 2rem;
}
.SpeakerImg {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  /* grid-template-columns: repeat(auto-fit,minmax(350px,3fr));   */
  flex-wrap: wrap;
}
.img {
  position: relative;
  justify-content: center;
  align-items: center;
}
.detail {
  overflow-x: scroll;
  position: absolute;
  left: 14px;
  bottom: 15px;
  align-items: center;
  width: 270px;
  height: 70px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.detail::-webkit-scrollbar {
  display: none;
}
.detail p {
  font-family: 'Montserrat';
  font-style: normal;
  color: #000000;
  text-align: center;
  margin: 0;
}

/* ***********Sponsor Area************ */
.sponsorArea {
  margin: 2rem 0;
  padding: 2rem 1rem;
}
.sponsorArea h1 {
  text-align: center;
  margin: 2rem 0;
}
.sponsors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.sponsors img {
  width: 100px;
  /* height:100px ; */
}
/* ********Style for Dialog*********** */
.ModalTitle {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  /* background-color: #9627ea; */
}
.ModalTitle h1 {
  color: white;
  font-size: 1.5rem;
  margin: 0;
}
.ModalTitle p {
  padding: 1rem;
}
.description {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: initial;
}
.speakerDesc h1 {
  font-size: 17px;
  font-weight: 800;
  /* margin-bottom: 3px; */
}
.speakerDesc p {
  font-size: 15px;
  font-weight: 400;
}
/* **********Enter Email Section********** */
.EnterEmail {
  max-width: 800px;
  margin: auto;
}
.emailInput h3 {
  padding: 1rem 0;
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

/* *****Style Starts for Register.js*********** */
.gotoTicket {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.regNav {
  padding: 1.5rem 0;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftSide {
  width: 100%;
  flex-basis: 50%;
  height: 100%;
}
.eventTicketInfo {
  background: #eaedfa;
  border-radius: 30px;
  padding: 2rem;
}

.eventTicketInfo img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
}

.eventTicketInfo h1 {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-top: 1rem;
}
.About h3 {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-top: 2rem;
  color: #000000;
}
.About p {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-top: 0.5rem;
  /* color: #666666; */
}
.timeTable {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}
.timeTable p,
.timeTable h1 {
  font-family: 'Montserrat' !important;
  font-style: normal;
  margin: 0.5rem 0;
  text-align: center;
}
.timeTable h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}
.timeTable p {
  font-weight: 300;
  font-size: 16px;
  line-height: 34px;
  color: #726666;
}
.rightSide {
  width: 100%;
  flex-basis: 50%;
}
.rightSide h2 {
  font-weight: 500;
  margin: 2rem 0 1rem 0;
}
.rightSide h3 {
  font-weight: 500;
  font-size: 1rem;
  color: #666666;
  margin: 1rem 0 0.5rem 0;
}
.rightSide h5 {
  font-weight: 600;
  font-size: 1rem;
  margin: 1rem 0 0.75rem 0;
  color: #666666;
}
.radioSelect {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0;
}
/* .radioSelect input{
  accent-color:#9627ea;
} */
.payNregBtn {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  gap: 1rem;
}
.PaymentArea h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}
.PaymentMode {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 3rem 0;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
}
.PaymentMode:hover {
  box-shadow: inset 0px 0px 15px 5px rgba(128, 128, 128, 0.25);
}
.PaymentBtn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.regModalEmail {
  padding: 1rem;
}
.regModalButton {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.ExhibitorBox {
  min-height: 100%;
  border-radius: 8px;

  object-fit: cover;
}

.AttendeeExhibitorMain {
  width: 268px;
  position: relative;
  min-height: 200px;
}

.ExhiDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.allExhibitors {
  margin: 2rem;
}
.allExhibitors h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 22px;
}
.DetailBox {
  display: flex;
  flex-direction: column;
  /* color: #fff; */
  /* opacity: 0.85; */
  padding: 0px 8px;
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 180px;
}
.TitleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0.85;
  padding: 0px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.exhibitorCompany {
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase !important;
  margin-left: -8px;
}
.exhibitorCategory {
  text-overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
}
/* *********Media query for RegAttendee/index.js page************** */
@media only screen and (max-width: 1199px) {
  .mainHeader {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 3rem;
    /* margin-top: 3rem; */
    /* padding: 2rem; */
  }
  .rightImg,
  .rightImg img {
    width: 100%;
  }
  .leftHeader {
    padding-left: 0;
  }

  .header .navItem {
    display: none;
  }
  .menuIcon {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .timelineItem {
    display: flex;
    flex-direction: column;
  }
  .timeNlocation {
    margin-left: 2.5rem;
  }
  .content {
    border: none;
    margin-right: 2rem;
  }
  .agendatimelineBody h4 {
    margin-left: 2.5rem;
  }
}
@media only screen and (min-width: 1199px) {
  .RegMain {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem 0;
  }
}
/* ********Media query for Register.js page********** */
@media only screen and (max-width: 1199px) {
  .RegMain {
    padding: 1rem 0;
  }
  .leftSide {
    flex-basis: 100%;
  }
  .rightSide {
    padding-left: 0;
    flex-basis: 100%;
  }
}
@media (max-width: 600px) {
  .eventTicketInfo {
    padding: 1rem;
  }
}

/* *******************Styles start for ChooseTicket.js**************** */

.firstContainer {
  padding: 2rem;
  background: #eaedfa;
  border-radius: 2rem;
  margin: 1rem 2rem;
}
.firstContainer h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;

  color: #9627ea;
}
.eventDetail {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.evDetailLeft {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-basis: 85%;
}
.evDetailLeft img {
  width: 342px;
  height: 230px;
  border-radius: 20px;
}
.evDates {
  flex-basis: 15%;
  display: grid;
  /* flex-direction: column; */
  gap: 1rem;
}
.evDates h2 {
  font-size: 20px;
}

/* ************************ */
.secContainer {
  display: flex;
  gap: 3rem;
  margin: 2rem 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.ticketCard {
  background: #ffffff;
  border: 2px solid rgba(128, 128, 128, 0.25);
  border-radius: 20px;
  padding: 0 1rem 1rem 1rem;
  width: 45%;
  cursor: pointer;
}
.ticketCard:hover {
  box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.25);
}
.ticketCard p {
  font-size: 14px;
}
/* .active{
  box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.25);
  border-color: greenyellow;
} */
.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.cardHeader h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #9627ea;
}
.cardHeader h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
}
.ticketBtn {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.ticketDates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/* *******media query for ChooseTicket.js************** */
@media only screen and (max-width: 1199px) {
  .firstContainer {
    padding: 2rem;
  }
  .eventDetail {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .evDates {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 900px) {
  .evDetailLeft {
    display: flex;
    flex-direction: column;
  }
  .evDetailLeft img {
    width: 100%;
  }
  .eventDetail {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .evDates {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 760px) {
  .firstContainer {
    margin: 0;
    padding: 1rem;
  }
  .secContainer {
    flex-direction: column;
    gap: 1.5rem;
    margin: 2rem 0;
  }
  .ticketCard {
    width: 100%;
  }
}

/* ***********Styles starts for RegSuccess.js page****************** */
.successMain {
  display: grid;
  justify-content: center;
}
.successImage {
  display: flex;
  justify-content: center;
}
.successMain h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #000000;
}
.successMain p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #000000;
}
.successDetail {
  background: #eaedfa;
  box-shadow: 0px 4px 15px rgba(128, 128, 128, 0.25);
  border-radius: 2rem;
  padding: 2rem;
  margin: 2rem;
}
.successDetail img {
  width: 100%;
  border-radius: 1.5rem;
}
.successDates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.successAbout h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #6f319d;
}
.successAbout h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: center;

  color: #000000;
}
.successStatrtDate h4 {
  margin: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;

  color: #000000;
}
.successStatrtDate h6 {
  margin: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #726666;
}
@media (max-width: 600px) {
  .successDetail {
    padding: 1rem;
  }
}

/* *************Styles for MySchedules.js************* */
.FavParent {
  text-align: center;
}
.ExportPdfBtn {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
}

.spkImg {
  position: relative;
  justify-content: center;
  align-items: center;
}

.details {
  overflow-x: scroll;
  position: absolute;
  left: 12px;
  bottom: 10px;
  align-items: center;
  width: 200px;
  height: 60px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 0.15rem;
}
.details::-webkit-scrollbar {
  display: none;
}
.details h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding: 1rem;
  color: #000000;
}
.nameSocialLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.4rem;
  /* align-items: center; */
  /* padding: 0.2rem; */
}
.details p {
  margin: 0;
  text-align: center;
  font-size: 13px;
}

/* Add this to your Style (or styles, depending on the exact name) */
.spkImgContainer {
  position: relative;
  cursor: pointer;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  color: white;
  text-align: center;
  padding: 10px;
}
