.wordCloudVote p {
  padding: 0.45rem 0;
}
.wordCloudVote p small {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: gray;
}
.wordCloudVote-card {
  min-width: 304px;
  max-height: 75vh;
  overflow: auto;
  border: 1px solid lightgray;
  border-radius: 0.75rem;
  background-color: white;
  box-shadow: 0 0 10px lightgray;
}
.wordCloudVote-card .card-header {
  margin: 0;
  padding: 1rem;
}
.wordCloudOption-container {
  padding: 0 1rem;
}
.wordCloudVote-btn-container {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.wordCloudVote-btn-container button {
  color: white;
  padding: 5px 1.5rem;
  border-radius: 2rem !important;
}
.wordCloudVote-edit-res-btn {
  border-radius: 2rem !important;
  margin: 4px 0 !important;
  padding: 4px 1rem !important;
  background-color: white !important;
}

.wordCloudVote-option {
  list-style: none;
  margin-top: 8px;
  padding: 8px 1rem;
  background-color: #f5f5fa;
  border: 1px solid #eee;
  border-radius: 5px;
  font-weight: 600;
  color: gray;
  cursor: pointer;
}
.wordCloudVote-option.selected {
  background-color: var(--secondary) !important;
  border-color: var(--secondary);
  color: white;
}

.WCAddOption-input {
  margin-top: 9px;
}
.WCAddOption-input input {
  background-color: #f5f5fa;
  padding-top: 9px;
  padding-bottom: 9px;
}
.wordCloudVote-wc {
  text-align: center;
  padding: 1rem 0;
}
.vote-reactWordCloud {
  height: 250px;
}

@media (max-width: 600px) {
  /* .wordCloudVote-card {
    width: 100%;
  } */
  .vote-reactWordCloud {
    height: 220px;
  }
}

/* @media (min-width: 601px) and (max-width: 1024px) {
  .wordCloudVote-card {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
} */
